import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CpdDto } from "../../features/pcsCpd/models/cpdDto";
import { CPDRecordStatusChange } from "../../features/pcsCpd/models/CPDRecordStatusChange";
import CpdResponseState from "../../features/pcsCpd/models/cpdResponseState";
import { CreditRecord } from "../../features/pcsCpd/models/creditRecord";
import { SearchCreditRecordsQuery } from "../../features/pcsCpd/models/searchCreditRecordsQuery";
import { MESSAGE_DETAILS_SAVED, MESSAGE_DLETED_SAVED } from "../utils/constant";

const initialState: CpdResponseState = {
    cpdDetails: null,
    creditRecords: null,
    cpdStatusHistory: null,

    cpdStateStatus: ApiRequestStatus.Idle,
}

export const getCpdStateAsync = createAsyncThunk<CpdDto, string>(
    'credits/getCpdStateAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Credits.getCpdDetails(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCreditRecordsStateAsync = createAsyncThunk<CreditRecord[], SearchCreditRecordsQuery>(
    'credits/getCreditRecordsStateAsync',
    async (searchCreditRecordsQuery, thunkAPI) => {
        try {
            return agent.Credits.getCreditRecords(searchCreditRecordsQuery);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateCpdStateAsync = createAsyncThunk<void, CpdDto>(
    'credits/updateCpdStateAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Credits.updateCpdDetails(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addCpdStateAsync = createAsyncThunk<void, CpdDto>(
    'credits/addCpdStateAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Credits.addCpdDetails(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cloneCpdStateAsync = createAsyncThunk<void, CpdDto>(
    'credits/cloneCpdStateAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Credits.cloneCpdDetails(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteCpdStateAsync = createAsyncThunk<void, string>(
    'credits/deleteCpdStateAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Credits.deleteCpdDetails(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCpdStatusHistoryAsync = createAsyncThunk<CPDRecordStatusChange[], string>(
    'credits/getCpdStatusHistoryAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Credits.getCpdStatusHistory(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const creditsSlice = createSlice({
    name: 'creditsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCpdStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            state.cpdDetails = action.payload;
        });
        builder.addCase(getCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCreditRecordsStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCreditRecordsStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            state.creditRecords = action.payload;
        });
        builder.addCase(getCreditRecordsStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(addCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(addCpdStateAsync.fulfilled, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(addCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(cloneCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(cloneCpdStateAsync.fulfilled, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(cloneCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while adding CPD.');
        });

        builder.addCase(updateCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateCpdStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(updateCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while updating CPD.');
        });

        builder.addCase(deleteCpdStateAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteCpdStateAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DLETED_SAVED);
        });
        builder.addCase(deleteCpdStateAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while deleting CPD.');
        });

        builder.addCase(getCpdStatusHistoryAsync.pending, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCpdStatusHistoryAsync.fulfilled, (state, action) => {
            state.cpdStateStatus = ApiRequestStatus.Fulfilled;
            state.cpdStatusHistory = action.payload;
        });
        builder.addCase(getCpdStatusHistoryAsync.rejected, (state) => {
            state.cpdStateStatus = ApiRequestStatus.Rejected;
        });
    })
})
