import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getWorkpostListAsync } from '../slices/workpostSlice';

export const useGetWorkpostList = () => {
    const dispatch = useAppDispatch();
    const { workpostList, workpostListStatus } = useAppSelector(state => state.workpost);

    useEffect(() => {
        if (!workpostList && workpostListStatus === ApiRequestStatus.Idle) {
            dispatch(getWorkpostListAsync());
          }
    }, [workpostList, workpostListStatus, dispatch]);

    return { workpostListStatus };
};
