import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getRegistrationDetailsAsync } from '../slices/pcsSlice';

export const useGetRegistrationDetails = () => {
    const dispatch = useAppDispatch();
    const { registrationDetails, saveRegistrationDetailsStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        if (registrationDetails === null && saveRegistrationDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getRegistrationDetailsAsync());
        }
    }, [registrationDetails, saveRegistrationDetailsStatus, dispatch]);

    return { saveRegistrationDetailsStatus };
};
