import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getEnrolmentYearsAsync } from '../slices/famEnrolmentSlice';

export const useGetEnrolmentYears = () => {
    const dispatch = useAppDispatch();
    const { famEnrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.famEnrolment);

    useEffect(() => {
        if (famEnrolmentYears === null && enrolmentYearsStatus === ApiRequestStatus.Idle) {
            dispatch(getEnrolmentYearsAsync());
        }
    }, [famEnrolmentYears, enrolmentYearsStatus, dispatch]);

    return { enrolmentYearsStatus };
};
