import { Box, Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AppTextInput from '../../app/components/AppTextInput';
import AppDatePicker from '../../app/components/AppDatePicker';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import { useAppSelector } from '../../app/store/configureStore';
import AppAutocomplete from '../../app/components/AppAutocomplete';
import { MapGendersToAutocompleteItems, MapTitlesToAutocompleteItems } from '../../app/utils/convertStaticDataToDropdownItems';
import { MapCountriesToAutocompleteItems } from '../../app/utils/convertStaticDataToAutocompleteItems';

interface Prop {
  reenrol: boolean;
}

export default function PersonalDetails({ reenrol }: Prop) {

  const methods = useFormContext();
  const { control, reset } = methods;

  const { personalDetailsFormField: {
    Title,
    FirstName,
    LastName,
    DOB,
    Gender,
    Nationality,
    MobileNumber
  } } = registrationFormModel;

  const { titles, genders, countries } = useAppSelector(state => state.ReferenceData);
  const { personalDetails } = useAppSelector(state => state.personalDetail);

  useEffect(() => {
    if (personalDetails && personalDetails !== null
      && titles !== null && titles.length > 0
      && genders !== null && genders.length > 0
      && countries !== null && countries.length > 0) {
      reset({
        [Title.name]: personalDetails.titleId,
        [FirstName.name]: personalDetails.firstName,
        [LastName.name]: personalDetails.lastName,
        [DOB.name]: personalDetails.dateOfBirth,
        [Gender.name]: personalDetails.genderId,
        [Nationality.name]: personalDetails.nationalityId,
        [MobileNumber.name]: personalDetails.mobile
      })
    }
  }, [titles, genders, countries]);

  return (
    <>
      <Box sx={{ minWidth: "100%" }} component={Paper}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

          <Grid item xs={12}>
            <AppAutocomplete control={control} name={Title.name}
              label={Title.label} items={MapTitlesToAutocompleteItems(titles ?? [])} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} name={FirstName.name}
              label={FirstName.label} disabled={true} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} name={LastName.name}
              label={LastName.label} disabled={true} />
          </Grid>

          <Grid item xs={12}>
            <AppDatePicker control={control} name={DOB.name}
              label={DOB.label} maxDate={new Date()} disabled={reenrol === true} />
          </Grid>

          <Grid item xs={12}>
            <AppAutocomplete control={control} name={Gender.name} label={Gender.label}
              items={MapGendersToAutocompleteItems(genders ?? [])} />
          </Grid>

          <Grid item xs={12}>
            <AppAutocomplete control={control} name={Nationality.name} label={Nationality.label}
              items={MapCountriesToAutocompleteItems(countries ?? [])} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} name={MobileNumber.name} label={MobileNumber.label} />
          </Grid>

          <Grid item xs={12}></Grid>

        </Grid>
      </Box>
    </>
  );
}
