import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getReferenceDataAsync } from '../slices/referenceDataSlice';

export const useReferenceData = () => {
    const dispatch = useAppDispatch();

    const { titles, genders, countries, countrySubdivisions, hospitals, educationInstitutions, institutionAwards,
        registrationTypes, schemes, specialities, workPostLevels, activityTypes, activityTypeTargets, pcsEnrolmentTypes,
        verificationStatus, goodPracticeDomains, cpdCategories, cpdRecordStatuses, pcsPdpStatuses, disciplines,
        areasOfPractice, publicationPreferences, famMembershipTypes, famAffiliateOnboardingStatuses, famAffiliateCategories,
        famAffiliatePathways, royalColleges, famOnboardingStatuses, famEditableOnboardingStatusCodes,
        alumniVolunteeringInterestTypes, famVolunteeringInterestTypes, documentTypes, famTransferDocTypes, products,
        paymentMethods, paymentStatuses, funds, recurrencePeriods, currencies, subscriptionIntervalUnits,
        staticDataStateStatus } = useAppSelector(state => state.ReferenceData);

    useEffect(() => {
        if (titles === null && genders === null && countries === null && countrySubdivisions === null && hospitals === null &&
            educationInstitutions === null && institutionAwards === null && registrationTypes === null && schemes === null &&
            specialities === null && workPostLevels === null && activityTypes === null && activityTypeTargets === null &&
            pcsEnrolmentTypes === null && verificationStatus === null && goodPracticeDomains === null && cpdCategories === null &&
            cpdRecordStatuses === null && pcsPdpStatuses === null && disciplines === null && areasOfPractice === null &&
            publicationPreferences === null && famMembershipTypes === null && famAffiliateOnboardingStatuses === null &&
            famAffiliateCategories === null && famAffiliatePathways === null && royalColleges === null && famOnboardingStatuses === null &&
            famEditableOnboardingStatusCodes === null && alumniVolunteeringInterestTypes === null && famVolunteeringInterestTypes === null &&
            documentTypes === null && famTransferDocTypes === null && products === null && paymentMethods === null && paymentStatuses === null &&
            funds === null && recurrencePeriods === null && currencies === null && subscriptionIntervalUnits === null &&
            staticDataStateStatus === ApiRequestStatus.Idle) {
            dispatch(getReferenceDataAsync());
        }
    }, [staticDataStateStatus, dispatch]);

    return { staticDataStateStatus };
};
