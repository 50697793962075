import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { FormProvider, useForm } from "react-hook-form";
import RequestFormModel from "./models/requestFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReductionsAndExemptionsValidationSchema } from "./models/requestValidationSchemas";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import { useState } from "react";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { toast } from "react-toastify";
import SuccessResponse from "./models/SuccessResponse";
import { AutocompleteItem } from "../../app/models/common/autocompleteItem";
import AppAutocomplete from "../../app/components/AppAutocomplete";

export default function ReductionsAndExemptions() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(ReductionsAndExemptionsValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        ReductionsAndExemptions: {
            DiscountTypeOrExemption,
            DateOfReturnFromParentalLeave,
            InfoForRequest
        }
    } = RequestFormModel;

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.ReductionsAndExemptions.toString(),
            details: `${DiscountTypeOrExemption.label}: ${formObj.DiscountTypeOrExemption}\r\n${DateOfReturnFromParentalLeave.label}: ${formObj.DateOfReturnFromParentalLeave}\r\n${InfoForRequest.label}: ${formObj.InfoForRequest}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = `Thank you for contacting the Fellows and Members Office. A member of the RCSI Fellows and Members Team will contact you directly once your request has been received.`;

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [DiscountTypeOrExemption.name]: '',
                            [DateOfReturnFromParentalLeave.name]: '',
                            [InfoForRequest.name]: ''
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    const discountOrExemptionType: AutocompleteItem[] = [
        { id: 'ParentalLeaveFeeWaiver', label: 'Parental leave fee waiver', code: 'ParentalLeaveFeeWaiver' },
        { id: 'Retired', label: 'Retired', code: 'Retired' },
        { id: 'HardshipAndIllness', label: 'Hardship and illness', code: 'HardshipAndIllness' }
    ];

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <FormProvider {...methods}>
            <Box sx={{ minWidth: "100%" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                    <Grid item xs={12}>
                        <BackToRequests />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h4'>Reductions and exemptions</Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                        <Typography variant='subtitle1'>
                            Discounted or exempt subscription rates are available to Fellows and Members
                            who have retired from clinical practice, are on parental leave, or currently
                            facing hardship or illness.
                            <br></br>
                            <br></br>
                            To request a reduction or exemption, please complete the form below.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ minWidth: "100%" }} component={Paper}>
                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                    <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                        <Typography variant='subtitle1' sx={{ fontWeight: "500" }}><b>Personal details</b></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control} name={DiscountTypeOrExemption.name}
                            label={DiscountTypeOrExemption.label} items={discountOrExemptionType} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextareaInput name={DateOfReturnFromParentalLeave.name} label={DateOfReturnFromParentalLeave.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextareaInput name={InfoForRequest.name} label={InfoForRequest.label} />
                    </Grid>

                    <Grid item xs={12}></Grid>

                </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}></Grid>

                    <Grid item xs={8}></Grid>

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            {MESSAGE_SUBMIT}
                        </Button>
                    </Grid>

                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        </FormProvider>
    </>
}