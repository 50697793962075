import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCurrentYearAsync } from '../slices/famSlice';

export const useGetCurrentYear = () => {
    const dispatch = useAppDispatch();
    const { currentYear, currentYearStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (currentYear === null && currentYearStatus === ApiRequestStatus.Idle) {
            dispatch(getCurrentYearAsync());
        }
    }, [currentYear, currentYearStatus, dispatch]);

    return { currentYearStatus };
};
