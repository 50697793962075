import { FormHelperText, Grid, Slider, Typography } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import AppInformationText from "./AppInformationText";

interface Props extends UseControllerProps {
    label: string;
    defaultValue: number;
    step: number;
    min: number;
    max: number;
    information_text?: string;
}

export default function AppSlider(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: props.defaultValue });

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant="body1">{props.label}</Typography>
                </Grid>

                <Grid item xs={11}>
                    <Slider
                        {...props}
                        {...field}
                        aria-label={props.label}
                        valueLabelDisplay="on"
                        marks
                    />
                    <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error?.message}</FormHelperText>
                </Grid>

                <Grid item xs={1}>
                    <AppInformationText information_text={props.information_text} />
                </Grid>

            </Grid>
        </>
    )
}