import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { formatDate, getValueFromIdList } from "../../../../app/utils/util";
import { CpdDto } from "../../models/cpdDto";
import { useForm } from "react-hook-form";
import cpdFormModel from "../../common/cpdFormModel";
import { getCpdStateAsync, deleteCpdStateAsync } from "../../../../app/slices/creditsSlice";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";

export function ViewCpdRecord() {

    const { formField } = cpdFormModel;
    const { reset } = useForm();
    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const dispatch = useAppDispatch();

    const { cpdCategories, activityTypes, cpdRecordStatuses, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { cpdDetails, cpdStateStatus } = useAppSelector(state => state.credits);

    const history = useHistory();

    useReferenceData();

    useEffect(() => {
        if (creditRecordId !== "") {
            dispatch(getCpdStateAsync(creditRecordId)).then((response) => {
                let cpdDetails = response.payload as CpdDto;
                reset(PopulateCpdDetailsObject(cpdDetails));
            });
        }
    }, [creditRecordId, reset, dispatch]);

    function handleDelete() {
        dispatch(deleteCpdStateAsync(cpdDetails?.creditRecordId!))
            .then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    history.push(`${AppPathEnum.PCS_VIEW_CPDS}${window.location.search}`);
                }
            });
    }

    function PopulateCpdDetailsObject(cpdDetailsObject: CpdDto) {
        return {
            [formField.ActivityDate.name]: formatDate(new Date(cpdDetailsObject.activityDate)),
            [formField.Category.name]: cpdDetailsObject.cpdCategoryId,
            [formField.ActivityType.name]: cpdDetailsObject.activityTypeId,
            [formField.Domains.name]: cpdDetailsObject.goodPracticeDomainIds,
            [formField.CreditsClaiming.name]: cpdDetailsObject.credits,
            [formField.Description.name]: cpdDetailsObject.description,
            [formField.DevelopmentPurpose.name]: cpdDetailsObject.purpose
        };
    }

    function getLabelFromValue(data: any, id: string) {
        return data?.filter((x: any) => x.id === id)[0]?.value;
    }

    if (cpdStateStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Grid container rowSpacing={4}>

            <Grid item xs={12} sm={12}>
                <Button variant="contained"
                    component={Link} to={`${AppPathEnum.PCS_VIEW_CPDS}`}
                    color='error'
                >
                    Search CPD Records
                </Button>
            </Grid>

            <Grid item sm={5}></Grid>

            <Grid item xs={12} sm={7}><Typography variant='h4'>View CPD record</Typography></Grid>

            <Grid item xs={3}>
                {formField.ActivityDate.label}
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                {cpdDetails && formatDate(new Date(cpdDetails?.activityDate))}
            </Grid>

            <Grid item xs={3}>
                {formField.Category.label}
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                {cpdDetails && cpdCategories && getLabelFromValue(cpdCategories, cpdDetails?.cpdCategoryId)}
            </Grid>

            <Grid item xs={3}>
                {formField.ActivityType.label}
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                {cpdDetails && activityTypes && getLabelFromValue(activityTypes, cpdDetails?.activityTypeId)}
            </Grid>

            <Grid item xs={3}>
                {formField.Domains.label}
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                <ul>
                    {cpdDetails && goodPracticeDomains && getValueFromIdList(goodPracticeDomains ?? [],
                        cpdDetails?.goodPracticeDomainIds!).map(function (name, index) {
                            return <li key={index}>{name}</li>
                        })}
                </ul>
            </Grid>

            <Grid item xs={3}>{formField.CreditsClaiming.label}</Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                {cpdDetails?.credits}
            </Grid>

            <Grid item xs={3}>{formField.Description.label}</Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                {cpdDetails?.description}
            </Grid>

            <Grid item xs={3}>{formField.DevelopmentPurpose.label}</Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={8.5}>
                {cpdDetails?.purpose}
            </Grid>

            <Grid item xs={12}></Grid>

        </Grid>

        <Grid container columns={16}>
            <Grid item xs={4}>
                <Button variant="contained"
                    component={Link} to={`${AppPathEnum.PCS_UPDATE_CPD_CREDIT_RECORD}/${cpdDetails?.creditRecordId}`}
                    disabled={cpdRecordStatuses?.find(x => x.id === cpdDetails?.cpdRecordStatusId)?.code === 'VF' ? true : false}
                    color='error' sx={{ width: '90%' }}
                >
                    Edit
                </Button>
            </Grid>

            <Grid item xs={4}>
                <Button variant="contained"
                    component={Link} to={`${AppPathEnum.PCS_CLONE_CPD_CREDIT_RECORD}/${cpdDetails?.creditRecordId}`}
                    color='error' sx={{ width: '90%' }}
                >
                    Clone
                </Button>
            </Grid>

            <Grid item xs={4}>
                <Button variant="contained" onClick={handleDelete}
                    disabled={cpdRecordStatuses?.find(x => x.id === cpdDetails?.cpdRecordStatusId)?.code === 'VF' ? true : false}
                    color='error' sx={{ width: '90%' }}>Delete</Button>
            </Grid>
        </Grid>
    </>
}
