import { Box, Button, Grid, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AffiliateHeader from './AffiliateHeader';
import Membership from './Membership';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { affiliateAndAssociateValidationSchemas } from './common/affiliateAndAssociateValidationSchemas';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personSlice';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_SAVE_AND_CONTINUE, MESSAGE_NEXT,
  MESSAGE_SUBMIT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NOT_A_VALUE, MESSAGE_PROCESSING_DETAILS,
  NO_FURTHER_ACTION_REQUIRED} from '../../app/utils/constant';
import { toast } from 'react-toastify';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { basketAsync, getAffiliateOnboardingDetailsAsync, updateAffiliateOnboardingDetailsAsync } from '../../app/slices/affiliateOnboardingSlice';
import { FamOnboardingStatus } from '../../app/models/staticData/famOnboardingStatus';
import { AffiliateStatusCodeEnum } from '../../app/enums/affiliateStatusCodeEnum';
import MemberLocation from '../../app/models/membership/MemberLocation';
import MemberPostalAddress from '../../app/models/membership/MemberPostalAddress';
import MemberPersonalDetails from '../../app/models/membership/MemberPersonalDetails';
import { getPaymentRedirectAsync, getPaymentSummaryAsync } from '../../app/slices/paymentSlice';
import MembershipPaymentSummary from '../../app/models/membership/MembershipPaymentSummary';
import { PaymentRedirectProps } from '../../app/models/membership/paymentRedirectProps';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import { SpaName } from '../../app/enums/spaName';
import { MembershipTypeEnum } from '../../app/enums/MembershipTypeEnum';
import Unauthorized from '../../app/errors/Unauthorized';
import { IdTokenClaims } from '../../app/models/account/idTokenClaims';
import UpdateAffiliateOnboardingDto from '../../app/models/affiliateOnboarding/updateAffiliateOnboardingDto';
import { useReferenceData } from '../../app/customHooks/useReferenceData';
import { useGetPersonalDetails } from '../../app/customHooks/useGetPersonalDetails';
import { useGetAffiliateOnboardingDetails } from '../../app/customHooks/useGetAffiliateOnboardingDetails';
import { useGetFamDetails } from '../../app/customHooks/useGetFamDetails';
import { useGetFamOnboardingDetails } from '../../app/customHooks/useGetFamOnboardingDetails';
import { useGetCurrentMembershipType } from '../../app/customHooks/useGetCurrentMembershipType';
import { AffiliateMembershipStepsEnum } from '../membershipTransfer/common/affiliateMembershipStepsEnum';
import { getPaymentIdFromBasketAsync } from '../../app/slices/shoppingBasketSlice';

export default function AffiliateMembershipForm() {

  const { instance } = useMsal();
  const idTokenClaimsObject = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims);

  const steps = [AffiliateMembershipStepsEnum.PersonalDetails, AffiliateMembershipStepsEnum.PostalAddress,
    AffiliateMembershipStepsEnum.LocationOfPractice, AffiliateMembershipStepsEnum.Membership];

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <MemberPersonalDetails />
      case 1:
        return <MemberPostalAddress />
      case 2:
        return <MemberLocation />
      case 3:
        return <Membership />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(affiliateAndAssociateValidationSchemas[activeStep])
  });

  const { trigger, formState: { isDirty } } = methods;
  const formObj = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { famAffiliateOnboardingStatuses } = useAppSelector(state => state.ReferenceData);
  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { currentMembershipType } = useAppSelector(state => state.famDetails);
  const { affiliateOnboardingDetails, updateAffiliateOnboardingDetailsStatus, basketId, basketIdStatus }
    = useAppSelector(state => state.affiliateOnboardingDetails);
  const { paymentRedirectStatus, paymentDetails, paymentSummaryStatus } = useAppSelector(state => state.payment);
  const { paymentId, paymentIdStatus } = useAppSelector(state => state.shoppingBasket);
  const { famOnboardingDetails } = useAppSelector(state => state.famOnboarding);

  const [pageLoading, setPageLoading] = useState(false);
  const [affiliateOnboardingStatus, setAffiliateOnboardingStatuses] = useState<FamOnboardingStatus>();

  useEffect(() => {
    if ((famAffiliateOnboardingStatuses !== null && famAffiliateOnboardingStatuses?.length > 0)
      && affiliateOnboardingDetails?.affiliateOnboardingStatusId && affiliateOnboardingDetails?.affiliateOnboardingStatusId !== null) {
      setAffiliateOnboardingStatuses(famAffiliateOnboardingStatuses?.find(x => x.id === affiliateOnboardingDetails?.affiliateOnboardingStatusId));
    }
  }, [affiliateOnboardingDetails, famAffiliateOnboardingStatuses, affiliateOnboardingStatus]);

  const { staticDataStateStatus } = useReferenceData();
  const { personalDetailsStatus } = useGetPersonalDetails();
  const { famDetailsStatus } = useGetFamDetails();
  const { affiliateOnboardingDetailsStatus } = useGetAffiliateOnboardingDetails();
  const { famOnboardingDetailsStatus } = useGetFamOnboardingDetails();
  const { currentMembershipTypeStatus } = useGetCurrentMembershipType();

  function MapToPersonalDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      titleId: formObj.Title,
      middleName: personalDetails?.middleName === formObj.MiddleName ? null : formObj.MiddleName,
      prefferedName: formObj.PreferredName,
      genderId: formObj.Gender,
      nationalityId: formObj.CountryOfNationality,
      dateOfBirth: formObj.DateOfBirth,
      mobile: formObj.PreferredPhoneNumber,
      disciplineId: formObj.Discipline,
      areaOfPracticeId: formObj.AreaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE ? '' : formObj.AreaOfPracticeMoreDetails
    }
    return data;
  }

  function MapToPostalAddressDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      postalAddress: formObj.PostalAddressLines,
      postalAddressCountryId: formObj.PostalAddressCountry
    }
    return data;
  }

  function MapToLocationDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      locationCountryId: formObj.LocationCountry,
      locationCountrySubdivisionId: formObj.CountrySubdivision === MESSAGE_NOT_A_VALUE ? '' : formObj.CountrySubdivision
    }
    return data;
  }

  function MapToMembershipDetails() {
    const data: UpdateAffiliateOnboardingDto = {
      ...affiliateOnboardingDetails,
      affiliateCategoryId: formObj.LevelOfStudyOrTraining,
      affiliatePathwayId: formObj.StatusAffiliateMemberPathway,
      pathwayComment: formObj.AffiliateMemberPathwayComments,
      reasons: formObj.ReasonToBecomeAffiliateMemberOfRCSI
    }
    return data;
  }

  const savePersonalDetails = (personalDetailsDto: PersonalDetailsDto) => {
    dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPersonalDetailsAsync(personId)).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      }
    });
  }

  const submitAffiliateMembershipApplication = (affiliateOnboardingDto: UpdateAffiliateOnboardingDto) => {
    dispatch(updateAffiliateOnboardingDetailsAsync(affiliateOnboardingDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getAffiliateOnboardingDetailsAsync()).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
        });
      }
    });
  }

  const handleNext = async () => {

    const isStepValid = await trigger();

    if (isStepValid === true && isDirty === true) {
      switch (activeStep) {
        case 0:
          savePersonalDetails(MapToPersonalDetails());
          break;
        case 1:
          savePersonalDetails(MapToPostalAddressDetails());
          break;
        case 2:
          savePersonalDetails(MapToLocationDetails());
          break;
        case 3:
          submitAffiliateMembershipApplication(MapToMembershipDetails());
          break;
        default:
          return "not a valid step";
      }
    }
    else if (isStepValid === true && isDirty === false) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  useEffect(() => {
    if (!paymentDetails && paymentId !== '' && paymentId !== null) {
      paymentId && dispatch(getPaymentSummaryAsync(paymentId));
    }
  }, [paymentDetails, paymentId, dispatch]);

  useEffect(() => {
    if ((basketId === '' || basketId === null) &&
      (famAffiliateOnboardingStatuses !== null && famAffiliateOnboardingStatuses.length > 0 &&
        famAffiliateOnboardingStatuses?.find(x => x.id === affiliateOnboardingDetails?.affiliateOnboardingStatusId)?.code === AffiliateStatusCodeEnum.Approved)) {
      dispatch(basketAsync());
    }
  }, [basketId, famAffiliateOnboardingStatuses, affiliateOnboardingDetails, dispatch]);

  useEffect(() => {
    if (basketId !== '' && basketId !== null) {
      dispatch(getPaymentIdFromBasketAsync(basketId));
    }
  }, [basketId, dispatch]);

  const paymentOnClickHandler = () => {
    if (paymentId && paymentId !== null) {
      setPageLoading(true);
      let paymentRedirectProps: PaymentRedirectProps = {
        cancelUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_AFFILIATE_ASSOCIATE_URI)}/${window.location.search}&cancelPayment=true`,
        paymentId: paymentId,
        successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_AFFILIATE_ASSOCIATE_URI)}`
      };
      dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
        window.location.replace(paymentRedirectResponse.payload);
      });
    }
  }

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (famOnboardingDetails !== null && famOnboardingDetails.length > 0 &&
    (currentMembershipType?.code === MembershipTypeEnum.MEMBER || currentMembershipType?.code === MembershipTypeEnum.FELLOW ||
      idTokenClaimsObject.extension_portalcrm_isFellowOnboarding === true ||
      idTokenClaimsObject.extension_portalcrm_isMemberOnboarding === true)) {
    toast.error(NO_FURTHER_ACTION_REQUIRED);
    return <Unauthorized message={NO_FURTHER_ACTION_REQUIRED} />
  }

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || personalDetailsStatus === ApiRequestStatus.Pending
    || famDetailsStatus === ApiRequestStatus.Pending
    || affiliateOnboardingDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || updateAffiliateOnboardingDetailsStatus === ApiRequestStatus.Pending
    || paymentRedirectStatus === ApiRequestStatus.Pending
    || paymentSummaryStatus === ApiRequestStatus.Pending
    || paymentIdStatus === ApiRequestStatus.Pending
    || basketIdStatus === ApiRequestStatus.Pending
    || currentMembershipTypeStatus === ApiRequestStatus.Pending
    || famOnboardingDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>
      <AffiliateHeader famAffiliateOnboardingStatus={affiliateOnboardingStatus} />
      <br></br>
      {(affiliateOnboardingStatus === undefined || affiliateOnboardingStatus.code === AffiliateStatusCodeEnum.Pending) &&
        <>
          <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
            {steps && activeStep !== steps.length && steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <br></br>
          {activeStep !== 0 && activeStep !== steps.length && (
            <Button variant="contained" onClick={_handleBack}>
              {MESSAGE_BACK_TO_PREVIOUS_STEP}
            </Button>
          )}

          {steps && activeStep === steps.length ? (
            <></>
          ) : (
            <>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid container rowSpacing={4}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {steps && activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT)}
                    </Button>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      }

      {affiliateOnboardingStatus?.code === AffiliateStatusCodeEnum.Approved &&
        <>
          <br></br>
          <br></br>
          <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>
              <MembershipPaymentSummary basketId={basketId} paymentOnClickHandler={paymentOnClickHandler}
                retryUrl={process.env.REACT_APP_AFFILIATE_ASSOCIATE_URI} SpaName={SpaName.AFFILIATE}
                membershipType='Affiliate' />
            </Grid>
          </Box>
        </>
      }
    </FormProvider >
  );
};
