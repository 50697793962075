import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { MapCountrySubdivisionToAutocompleteItems } from "../../utils/convertStaticDataToDropdownItems";
import MembershipFormModel from "./membershipFormModel";
import { MESSAGE_NOT_A_VALUE } from "../../utils/constant";
import { useAppSelector } from "../../store/configureStore";
import AppAutocomplete from "../../components/AppAutocomplete";
import { MapCountriesToAutocompleteItems } from "../../utils/convertStaticDataToAutocompleteItems";

export default function MemberLocation() {
    const { countries, countrySubdivisions } = useAppSelector(state => state.ReferenceData);
    const { personalDetails } = useAppSelector(state => state.personalDetail);

    const methods = useFormContext();
    const { control, reset, getValues, setValue, watch } = methods;
    methods.watch();

    const {
        formField: {
            LocationCountry,
            CountrySubdivision
        }
    } = MembershipFormModel;

    useEffect(() => {
        if (personalDetails !== undefined && personalDetails !== null
            && countries && countries?.length > 0 && countrySubdivisions && countrySubdivisions?.length > 0) {

            reset({
                [LocationCountry.name]: (personalDetails.locationCountryId === null ||
                    personalDetails.locationCountryId === undefined) ? '' : personalDetails.locationCountryId,
                [CountrySubdivision.name]: (personalDetails.locationCountrySubdivisionId === null ||
                    personalDetails.locationCountrySubdivisionId === undefined) ? '' : personalDetails.locationCountrySubdivisionId
            });

            if ((!countrySubdivisions || countrySubdivisions?.length === 0) ||
                (personalDetails.locationCountryId !== null && (personalDetails.locationCountrySubdivisionId === null ||
                    personalDetails.locationCountrySubdivisionId === undefined))) {
                setValue(CountrySubdivision.name, MESSAGE_NOT_A_VALUE);
            }
        }
    }, [personalDetails, countries, countrySubdivisions, reset]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (name === LocationCountry.name && type === 'change') {
                if (countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)) &&
                    countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)).length > 0) {
                    setValue(CountrySubdivision.name, '');
                }
                else {
                    setValue(CountrySubdivision.name, MESSAGE_NOT_A_VALUE);
                }
            }
        });
    }, [LocationCountry.name, watch]);

    return <>
        <Box sx={{ width: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Location of practice</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete
                        control={control}
                        name={LocationCountry.name}
                        label={LocationCountry.label}
                        information_text={LocationCountry.information_text}
                        items={MapCountriesToAutocompleteItems(countries ?? [])}
                    />
                </Grid>

                {countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)) &&
                    countrySubdivisions?.filter(x => x.countryId === getValues(LocationCountry.name)).length > 0 &&
                    <Grid item xs={12}>
                        <AppAutocomplete control={control} name={CountrySubdivision.name} label={CountrySubdivision.label}
                            items={MapCountrySubdivisionToAutocompleteItems(countrySubdivisions?.filter(
                                x => x.countryId === getValues(LocationCountry.name)) ?? [])}
                        />
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}