import { AutocompleteItem } from "../models/common/autocompleteItem";
import { Country } from "../models/staticData/country";
import { Hospital } from "../models/staticData/hospital";
import { Institution } from "../models/staticData/institution";

export function MapInstitutionsToAutocompleeItems(institutions: Institution[], countryId: string) {
    let institutionList: AutocompleteItem[] = [];

    institutions?.filter(x => x.countryId === countryId).map((item: Institution) => {
        institutionList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return institutionList;
}

export function MapCountriesToAutocompleteItems(countries: Country[]) {
    let countryList: AutocompleteItem[] = [];

    countries?.map((item: Country) => {
        countryList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return countryList;
}

export function MapHospitalsToAutocompleteItems(hospitals: Hospital[]) {
    let hospitalList: AutocompleteItem[] = [];

    hospitals?.map((item: Hospital) => {
        hospitalList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return hospitalList;
}

export function yesNoObjectsToAutocompleteItem() {
    var yesNoObjects: AutocompleteItem[] = [];
    yesNoObjects.push({ id: "Yes", label: "Yes" });
    yesNoObjects.push({ id: "No", label: "No" });
    return yesNoObjects;
}
