import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getPcsCurrentYearAsync } from '../slices/pcsSlice';

export const useGetPcsCurrentYear = () => {
    const dispatch = useAppDispatch();
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        if (!currentYear && currentYearStatus === ApiRequestStatus.Idle) {
            dispatch(getPcsCurrentYearAsync());
          }
    }, [currentYear, currentYearStatus, dispatch]);

    return { currentYearStatus };
};
