import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { Subscription } from "../models/payment/subscription";

interface requestState {
    subscriptions: Subscription[] | null;
    subscriptionsStatus: ApiRequestStatus;
}

const initialState: requestState = {
    subscriptions: null,
    subscriptionsStatus: ApiRequestStatus.Idle
}

export const getCardSubscriptionAsync = createAsyncThunk<Subscription[]>(
    'cardSubscriptionSlice/getCardSubscriptionAsync',
    async (_, thunkAPI) => {
        try {
            return agent.CardSubscription.getCardSubscriptionAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cardSubscriptionSlice = createSlice({
    name: 'cardSubscriptionSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getCardSubscriptionAsync.pending, (state) => {
            state.subscriptionsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCardSubscriptionAsync.fulfilled, (state, action) => {
            state.subscriptions = action.payload;
            state.subscriptionsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getCardSubscriptionAsync.rejected, (state) => {
            state.subscriptionsStatus = ApiRequestStatus.Rejected;
        });
    })
})
