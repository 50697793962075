import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCommitmentsAsync } from '../slices/donationsSlice';

export const useGetDonationDetails = () => {
    const dispatch = useAppDispatch();
    const { donationDetails, donationDetailsStatus } = useAppSelector(state => state.donations);

    useEffect(() => {
        if (donationDetails === null && donationDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getCommitmentsAsync());
        }
    }, [donationDetails, donationDetailsStatus, dispatch]);

    return { donationDetailsStatus };
};
