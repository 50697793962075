import { FormControl, FormHelperText, Grid, Autocomplete, TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import AppInformationText, { AppInformationHtmlText } from "./AppInformationText";
import { AutocompleteItem } from "../models/common/autocompleteItem";

interface Props extends UseControllerProps {
    label: string;
    items: AutocompleteItem[];
    disabled?: boolean;
    information_text?: string;
    html_information_text?: React.ReactNode;
}

export default function AppAutocomplete(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: '' });

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={11}>
                    <FormControl fullWidth error={!!fieldState.error}>
                        <Autocomplete
                            {...field}
                            {...props}
                            disablePortal
                            options={props.items}
                            getOptionLabel={(option) => {
                                const selectedLabel = props.items.find(x => x.id === option)?.label;
                                if (typeof (option) === 'string' && selectedLabel) {
                                    return selectedLabel;
                                }

                                if (typeof (option) === 'object') {
                                    return option.label;
                                }
                                return "";
                            }}
                            value={field.value === '' ? null : field.value}
                            isOptionEqualToValue={(option, value) => option.id === value}
                            onChange={(_, item: any) => {
                                field.onChange(item?.id);
                            }}
                            renderInput={(params) => <TextField {...params} label={props.label} />}
                        />
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                </Grid>

                {props.information_text &&
                    <Grid item xs={1}>
                        <AppInformationText information_text={props.information_text} />
                    </Grid>}

                {props.html_information_text &&
                    <Grid item xs={1}>
                        <AppInformationHtmlText information_text={props.html_information_text} />
                    </Grid>}


            </Grid>
        </>
    )
}