import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { msalConfig } from "../../authConfig";

export default function Logout() {
    const { instance, accounts } = useMsal();

    const [idToken, setIdToken] = useState<string>();

    useEffect(() => {
        const request = {
            scopes: ["openid"],
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then(response => {
            setIdToken(response.idToken);
        }).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup(request).then(response => {
                    setIdToken(response.idToken);
                });
            }
        });
    }, [instance, accounts]);

    useEffect(() => {
        if (idToken) {
            instance.logoutRedirect({
                postLogoutRedirectUri: msalConfig.auth.redirectUri,
                account: instance.getActiveAccount(),
                idTokenHint: idToken
            });         
        }
    }, [idToken, instance]);

    return <></>
}