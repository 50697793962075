import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { cpdValidationSchema } from "../../common/cpdValidationSchema";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { getCpdStateAsync, cloneCpdStateAsync } from "../../../../app/slices/creditsSlice";
import { CpdDto } from "../../models/cpdDto";
import cpdFormModel from "../../common/cpdFormModel";
import AppDatePicker from "../../../../app/components/AppDatePicker";
import { Link, useHistory, useParams } from "react-router-dom";
import { MapDomainsToCheckboxItems, MapCategoriesToAutocompleteItems, MapActivityTypesToAutocompleteItems }
    from "../../../../app/utils/convertStaticDataToDropdownItems";
import AppCheckboxFieldWithLeftAlignedLabel from "../../../../app/components/AppCheckboxFieldWithLeftAlignedLabel";
import AppSlider from "../../../../app/components/AppSlider";
import { API_REQUEST_FULLFILLED } from "../../../../app/utils/constant";
import AppTextareaInput from "../../../../app/components/AppTextareaInput";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../app/enums/userClaims";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";
import { AutocompleteItem } from "../../../../app/models/common/autocompleteItem";
import AppAutocomplete from "../../../../app/components/AppAutocomplete";

export default function CloneCpdForm() {
    const {
        formField: {
            ActivityDate,
            Category,
            ActivityType,
            Domains,
            CreditsClaiming,
            Description,
            DevelopmentPurpose
        }
    } = cpdFormModel;

    const { control, watch, getValues, reset, handleSubmit } = useForm({
        resolver: yupResolver(cpdValidationSchema)
    });

    const { creditRecordId } = useParams<{ creditRecordId: string }>();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { cpdCategories, activityTypes, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);

    const { cpdDetails, cpdStateStatus } = useAppSelector(state => state.credits);

    const [filteredActivityTypes, setFilteredActivityTypes] = useState<AutocompleteItem[]>([]);

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === Category.name && type === 'change') {
                const activityTypeList = activityTypes?.filter(x => x.cpdCategoryId === getValues(Category.name));
                setFilteredActivityTypes(MapActivityTypesToAutocompleteItems(activityTypeList ?? []));
            }
        });
    }, [Category.name, getValues, filteredActivityTypes, activityTypes, watch]);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    useReferenceData();

    useEffect(() => {
        dispatch(getCpdStateAsync(creditRecordId)).then((response: any) => {
            reset(PopulateCpdDetailsObject(response.payload as CpdDto));
        });
    }, [creditRecordId, reset, dispatch]);

    function PopulateCpdDetailsObject(cpdDetailsObject: CpdDto) {
        return {
            [ActivityDate.name]: '',
            [Category.name]: cpdDetailsObject.cpdCategoryId,
            [ActivityType.name]: cpdDetailsObject.activityTypeId,
            [Domains.name]: cpdDetailsObject.goodPracticeDomainIds,
            [CreditsClaiming.name]: cpdDetailsObject.credits,
            [Description.name]: cpdDetailsObject.description,
            [DevelopmentPurpose.name]: cpdDetailsObject.purpose
        };
    }

    async function submitForm(values: any) {
        dispatch(cloneCpdStateAsync(MapToCpdDetailsDto(values)))
            .then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    history.push(`${AppPathEnum.PCS_VIEW_CPD}/${response.payload}${window.location.search}`);
                }
            });
    }

    function MapToCpdDetailsDto(values: any) {
        const data: CpdDto = {
            activityDate: new Date(new Date(values.ActivityDate).toString().split('+')[0]),
            cpdCategoryId: values.Category,
            activityTypeId: values.ActivityType,
            goodPracticeDomainIds: values.Domains,
            credits: Number(values.CreditsClaiming),
            description: values.Description,
            purpose: values.DevelopmentPurpose,
            personId: personId
        }
        return data;
    }

    function isPageProcessing() {
        return (cpdStateStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending);
    }

    return <>
        {isPageProcessing() && <LoadingComponent message="Processing CPD record.." />}

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={4}>

                    <Grid item xs={12}>
                        <Typography variant='h4'>Clone CPD</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Please update your CPD details to clone.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <AppDatePicker
                            control={control}
                            name={ActivityDate.name}
                            label={ActivityDate.label}
                            information_text={ActivityDate.informationText} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete
                            control={control}
                            name={Category.name}
                            label={Category.label}
                            items={MapCategoriesToAutocompleteItems(cpdCategories ?? [])}
                            information_text={Category.informationText} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete
                            control={control}
                            name={ActivityType.name}
                            label={ActivityType.label}
                            items={filteredActivityTypes}
                            information_text={ActivityType.informationText} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppCheckboxFieldWithLeftAlignedLabel
                            control={control}
                            name={Domains.name}
                            label={Domains.label}
                            options={MapDomainsToCheckboxItems(goodPracticeDomains ?? [])}
                            information_text={Domains.informationText} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppSlider control={control}
                            label={CreditsClaiming.label}
                            name={CreditsClaiming.name}
                            defaultValue={0.5} step={0.5} min={0.5} max={40}
                            information_text={CreditsClaiming.informationText} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextareaInput control={control}
                            name={Description.name}
                            label={Description.label}
                            information_text_with_textarea={Description.informationText} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextareaInput control={control}
                            name={DevelopmentPurpose.name}
                            label={DevelopmentPurpose.label}
                            information_text_with_textarea={DevelopmentPurpose.informationText} />
                    </Grid>

                    <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            variant="outlined"
                            component={Link} to={`${AppPathEnum.PCS_VIEW_CPD}/${creditRecordId}`}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" type='submit' sx={{ mt: 3, ml: 1 }}
                            onClick={handleSubmit(submitForm)}>Submit</Button>
                    </Grid>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}></Grid>

                </Grid>
            </Box>
        </Paper>
    </>
}
