import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSelector } from "../../../../../app/store/configureStore";
import { PlannedActivities } from "./PlannedActivities";
import { useParams } from "react-router-dom";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import ViewPlannedActivities from "../viewPlan/VewPlannedActivities";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { useGetPcsEnrolmentYears } from "../../../../../app/customHooks/useGetPcsEnrolmentYears";
import { useReferenceData } from "../../../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";
import { useGetPcsCurrentYear } from "../../../../../app/customHooks/useGetPcsCurrentYear";

export default function PlanDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    const methods = useForm();
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();
    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear | undefined>();

    useReferenceData();
    useGetPcsCurrentYear();
    useGetPcsEnrolmentYears();

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            setEnrolmentYear(enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [enrolmentYears]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYear) {
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYears]);

    const isCurrentYearPlan = () => {
        return enrolmentYear?.year === currentYear;
    }

    const canUpdatePlannedActivities = () => {
        return (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.DT] ||
            pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.NS]) && isCurrentYearPlan();
    }

    if (enrolmentYearsStatus === ApiRequestStatus.Pending
        || staticDataStateStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}>
                        {canUpdatePlannedActivities() === true &&
                            <PlannedActivities />
                        }
                        {canUpdatePlannedActivities() === false &&
                            <ViewPlannedActivities />
                        }
                    </Grid>
                </Grid>
            </Paper>
        </FormProvider >
    </>
}
