import { StyleSheet, Text, Font } from '@react-pdf/renderer';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    table: {
        fontSize: 10,
        width: 550,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    },
    boldText: {
        fontFamily: 'Open Sans',
        fontWeight: 800
    },
    footerText: {
        alignContent: 'flex-start',
        width: "100%",
        fontSize: 9
    },
    verticalAlignBottom: {
        position: 'absolute',
        bottom: 30,
        left: 20,
        right: 20,
        textAlign: 'center'
    }
});

export function Footer() {
    return (
        <Text style={[styles.table, styles.row, styles.footerText, styles.verticalAlignBottom]} fixed>
            <Text style={[styles.boldText]}>EDUCATIONAL EXCELLENCE IN</Text>{"\n"}
            SURGERY MEDICINE PHARMICY PHYSIOTHERAPY NURSING & MIDWIFERY RESEARCH LEADERSHIP POSTGRADUATE STUDIES SPORTS & EXERCISE MEDICINE DENTISTRY RADIOLOGY
        </Text>
    )
}
