import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFilesAsync } from '../slices/personSlice';

export const useGetFiles = () => {
    const dispatch = useAppDispatch();
    const { files, getDocumentsStatus } = useAppSelector(state => state.personalDetail);

    useEffect(() => {
        if (files === null && getDocumentsStatus === ApiRequestStatus.Idle) {
            dispatch(getFilesAsync());
        }
    }, [files, getDocumentsStatus, dispatch]);

    return { getDocumentsStatus };
};
