import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import { history } from "../..";
import AppToasterWithErrorMessages from "../components/AppToasterWithErrorMessages";
import { PlannedActivity } from "../../features/pcsPlan/models/plannedActivity";
import { ReviewerDetails } from "../../features/pcsPlan/models/reviewerDetails";
import { CancelPlanParameters } from "../../features/pcsPlan/models/cancelPlanParameters";
import { ConfirmReviewerDetailsParameters } from "../../features/pcsPlan/models/confirmReviewerDetailsParameters";
import { RevieweeCommentDto } from "../../features/pcsPlan/models/revieweeCommentDto";
import { PersonalDetailsDto } from "../models/person/personalDetailsDto";
import { FAMDetails } from "../models/membership/famDetails";
import { AlumnusDetails } from "../../features/updateProfile/models/alumniDetails";
import { FAMOnboarding } from "../models/membership/famOnboarding";
import { EducationalDetailsDto } from "../models/person/educationalDetailsDto";
import { VolunteeringInterestDto } from "../models/volunteering/common/volunteeringInterestDto";
import { PaymentRedirectProps } from "../models/membership/paymentRedirectProps";
import { CreateDonationBasketCommand } from "../models/donation/createDonationBasketCommand";
import { CreateRequestTaskCommand } from "../models/request/createRequestTaskCommand";
import { toast } from "react-toastify";
import UpdateAffiliateOnboardingDto from "../models/affiliateOnboarding/updateAffiliateOnboardingDto";
import { WorkPostDto } from "../models/workpost/workPostDto";
import { CreateDirectDebitMandateCommand } from "../models/directDebit/createDirectDebitMandateCommand";
import { CreatePaymentIntentFromBasketCommand } from "../models/directDebit/createPaymentIntentFromBasketCommand";
import { CpdDto } from "../../features/pcsCpd/models/cpdDto";
import { SearchCreditRecordsQuery } from "../../features/pcsCpd/models/searchCreditRecordsQuery";
import { PCSDetailsDto } from "../models/pcsEnrolment/pcsDetailsDto";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.response.use(async response => {
    return response;
}, (error: AxiosError) => {
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key])
                    }
                }
                if (modelStateErrors.length > 1) {
                    AppToasterWithErrorMessages(modelStateErrors);
                }
                else if (modelStateErrors.length === 1) {
                    toast.error(modelStateErrors[0][0]);
                }
                throw modelStateErrors.flat();
            }
            break;
        case 401:
            history.push({ pathname: '/unauthorized' });
            break;
        case 404:
        case 500:
            history.push({ pathname: '/server-error' });
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
})

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    getConfig: (url: string, config: {}) => axios.get(url, config).then(responseBody),
    post: (url: string, body: {}, config: {} = {}) => axios.post(url, body, config).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
}

const AffiliateOnboarding = {
    getAffiliateOnboarding: () => requests.get(`AffiliateOnboarding`),
    getAffiliateOnboardingCanPayStatusCodesAsync: () => requests.get(`AffiliateOnboarding/CanPayStatusCodes`),
    updateAffiliateOnboarding: (data: UpdateAffiliateOnboardingDto) => requests.put(`AffiliateOnboarding`, data),
    basketAsync: () => requests.post(`AffiliateOnboarding/Basket`, {})
}

const Alumni = {
    getAlumniDetails: (personId: string) => requests.get(`Alumni/${personId}`),
    updateAlumniDetails: (data: AlumnusDetails) => requests.put(`Alumni`, data)
}

const CardSubscription = {
    getCardSubscriptionAsync: () => requests.get(`CardSubscription`)
}

const Credits = {
    addCpdDetails: (data: CpdDto) => requests.post(`Credits`, data),
    updateCpdDetails: (data: CpdDto) => requests.put(`Credits`, data),
    cloneCpdDetails: (data: CpdDto) => requests.post(`Credits`, data),
    deleteCpdDetails: (id: string) => requests.delete(`Credits/${id}`),
    getCpdDetails: (id: string) => requests.get(`Credits/${id}`),
    getCpdStatusHistory: (id: string) => requests.get(`Credits/${id}/StatusHistory`),
    getCreditRecords: (data: SearchCreditRecordsQuery) =>
        axios.get(`Credits`, {
            params: {
                personId: data.personId,
                year: data.year,
                cpdCategoryID: data.cpdCategoryID,
                cpdRecordStatusID: data.cpdRecordStatusID
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(responseBody)
}

const DirectDebitMandate = {
    submitDirectDebitDetailsAsync: (createDirectDebitMandateCommand: CreateDirectDebitMandateCommand) =>
        requests.post(`DirectDebitMandate/FromBasket`, createDirectDebitMandateCommand),
    getDirectDebitMandateByIdAsync: (id: string) => requests.get(`DirectDebitMandate/${id}`),
    getDirectDebitMandatesAsync: () => requests.get(`DirectDebitMandate`),
    deleteDirectDebitMandateByIdAsync: (id: string) => requests.delete(`DirectDebitMandate/${id}`),
    payByDDMandateFromBasketAsync: (createPaymentIntentFromBasketCommand: CreatePaymentIntentFromBasketCommand) =>
        requests.post(`DirectDebitMandate/PayByDDMandateFromBasket`, createPaymentIntentFromBasketCommand)
}

const Donations = {
    getCommitmentsAsync: () => requests.get(`Donations/Commitments`),
    submitToBasketAsync: (donationCommand: CreateDonationBasketCommand) => requests.post(`Donations/Basket`, donationCommand)
}

const Fam = {
    getFAMDetails: (personId: string) => requests.get(`Fam/${personId}`),
    getFamSubscriptionsAsync: () => requests.get('Fam/Subscriptions'),
    getCurrentYear: () => requests.get(`Fam/CurrentYear`),
    getCurrentMembershipTypeAsync: () => requests.get(`Fam/CurrentMembershipType`),
    updateFamDetails: (data: FAMDetails) => requests.put(`Fam`, data),
    addFamDetails: (data: FAMDetails) => requests.post(`Fam`, data),
    createReEnrolBasketAsync: () => requests.post(`Fam/CreateReEnrolBasket`, {}),
    getFamExamDetailsAsync: () => requests.get(`Fam/Exams`),
    getCanUseDirectDebitMandateAsync: () => requests.get(`Fam/CanUseDirectDebitMandate`)
}

const FamEnrolment = {
    getEnrolmentYearsAsync: () => requests.get(`FamEnrolment`),
    basketAsync: () => requests.post(`FamEnrolment/ReEnrolBasket`, {}),
    nextYearEnrolBasketAsync: () => requests.post(`FamEnrolment/NextYearEnrolBasket`, {})
}

const FamOnboarding = {
    getFamOnboardingDetailsAsync: () => requests.get(`FamOnboarding`),
    getFamOnboardingCanPayStatusCodesAsync: () => requests.get(`FamOnboarding/CanPayStatusCodes`),
    getByIdAsync: (id: string) => requests.get(`FamOnboarding/${id}`),
    updateFamOnboardingDetailsAsync: (data: FAMOnboarding) => requests.put(`FamOnboarding`, data),
    submitFamOnboardingDetailsAsync: (famExamId: string) => requests.put(`FamOnboarding/Submit`, { FamExamId: famExamId }),
    basketAsync: (famExamId: string) => requests.post(`FamOnboarding/Basket`, { FamExamId: famExamId })
}

const FamTransfer = {
    getTransferInformation: () => requests.get(`FamTransfer`),
    getFamTransferCanPayStatusCodesAsync: () => requests.get(`FamTransfer/CanPayStatusCodes`),
    saveTransferInformation: (data: FAMOnboarding) => requests.post(`FamTransfer`, data),
    updateTransferInformation: (data: FAMOnboarding) => requests.put(`FamTransfer`, data),
    saveDocument: (data: FormData) => requests.post(`FamTransfer/Document`, data, {
        headers: {
            "content-type": "multipart/form-data"
        },
    }),
    submitTransferDetails: (famExamId: string) => requests.put(`FamTransfer/Submit`, { FamExamId: famExamId }),
    getFiles: () => requests.get(`FamTransfer/Documents`),
    downloadFile: (name: string) => requests.getConfig(`FamTransfer/Document/${name}`, {
        responseType: 'blob',
    }),
    deleteFile: (fileType: string) => requests.delete(`FamTransfer/Document?FileType=${fileType}`),
    basketAsync: () => requests.post(`FamTransfer/Basket`, {})
}

const Payment = {
    getPaymentRedirectAsync: (paymentRedirectProps: PaymentRedirectProps) =>
        requests.get(`Payment/Redirect?SuccessUrl=${paymentRedirectProps.successUrl}&CancelUrl=${paymentRedirectProps.cancelUrl}&PaymentId=${paymentRedirectProps.paymentId}`),
    getPaymentByPaymentIdAsync: (id: string) => requests.get(`Payment/${id}`),
    getPaymentsAsync: () => requests.get(`Payment`),
    getSelfServiceAsync: () => requests.get(`Payment/SelfService`)
}

const PcsPdp = {
    getReviewerDetails: (personId: string) => requests.get(`PcdPdp?ReviewerPersonId=${personId}`),
    updatePlannedActivity: (data: PlannedActivity) => requests.put(`PcdPdp`, data),
    submitReviewerDetails: (data: ReviewerDetails) => requests.post(`PcdPdp/Reviewer`, data),
    saveRevieweeComment: (data: RevieweeCommentDto) => requests.put(`PcdPdp/Comment`, data),
    confirmReviewerDetails: (data: ConfirmReviewerDetailsParameters) => requests.put(`PcdPdp/Reviewer`, data),
    cancelPlan: (data: CancelPlanParameters) => requests.put(`PcdPdp/Cancel`, data),
    searchReviewerByImcNumber: (imcNumber: string) => requests.get(`PcdPdp/Reviewer?IMCNumber=${imcNumber}`),
    searchReviewerByPersonId: (personId: string) => requests.get(`PcdPdp/Reviewer?PersonId=${personId}`),
    getActivitiesAcrossDomains: () => requests.get(`PcdPdp/DomainSummary`)
}

const Pcs = {
    getCurrentYearAsync: () => requests.get(`PCS/CurrentYear`),
    getRegistrationDetailsAsync: () => requests.get(`PCS`),
    getCreditSummaryAsync: () => requests.get(`PCS/Summary`),
    saveRegistrationDetailsAsync: (data: PCSDetailsDto) => requests.put(`PCS/RegistrationDetails`, data),
    submitPersonalDetailsAsync: (data: PersonalDetailsDto) => requests.put(`PCS/PersonalDetails`, data),
    updateEducationAsync: (data: EducationalDetailsDto) => requests.put(`PCS/Education`, data)
}

const PcsEnrolment = {
    getPcsEnrolmentYearsAsync: () => requests.get(`PcsEnrolment`),
    enrolBasket: (isCombinedPayment: boolean) => requests.post(`PcsEnrolment/EnrolBasket`, { combined: isCombinedPayment }),
    canPayMembershipAsync: () => requests.get(`PcsEnrolment/CanPayMembership`)
}

const PersonalDetails = {
    getPersonalDetails: (personId: string) => requests.get(`Person/${personId}`),
    submitPersonalDetails: (data: PersonalDetailsDto) => requests.post(`Person`, data),
    updatePersonalDetails: (data: PersonalDetailsDto) => requests.put(`Person/${data.id}`, data),
    getFiles: () => requests.get(`Person/Documents`),
    getFile: (id: string, name: string) => requests.getConfig(`Person/Document/${id}/${name}`, {
        responseType: 'blob',
    })
}

const Pmd = {
    getEducationAsync: () => requests.get(`Pmd`),
    saveEducationAsync: (data: EducationalDetailsDto) => requests.post(`Pmd`, data),
    updateEducationAsync: (data: EducationalDetailsDto) => requests.put(`Pmd`, data)
}

const ReferenceData = {
    getReferenceData: () => requests.get(`ReferenceData`)
}

const Requests = {
    submitRequestAsync: (requestCommand: CreateRequestTaskCommand) => requests.post(`Request`, requestCommand)
}

const ShoppingBasket = {
    getPaymentIdFromBasketAsync: (shoppingBasketId: string) => requests.post(`Payment/FromBasket`, { ShoppingBasketId: shoppingBasketId })
}

const VolunteeringInterests = {
    getVolunteeringInterestsAsync: () => requests.get(`VolunteeringInterests`),
    updateVolunteeringInterestsAsync: (volunteeringInterests: VolunteeringInterestDto[]) =>
        requests.put(`VolunteeringInterests`, { volunteeringInterests: volunteeringInterests })
}

const WorkPost = {
    getWorkpostListAsync: () => requests.get(`Workpost`),
    getWorkpostByIdAsync: (workpostId: string) => requests.get(`Workpost/${workpostId}`),
    submitWorkpostAsync: (data: WorkPostDto) => requests.post(`Workpost`, data),
    deleteWorkpostAsync: (workpostId: string) => requests.delete(`Workpost/${workpostId}`)
}

const agent = {
    AffiliateOnboarding,
    Alumni,
    CardSubscription,
    Credits,
    DirectDebitMandate,
    Donations,
    Fam,
    FamEnrolment,
    FamOnboarding,
    FamTransfer,
    Payment,
    PcsPdp,
    Pcs,
    PcsEnrolment,
    PersonalDetails,
    Pmd,
    ReferenceData,
    Requests,
    ShoppingBasket,
    VolunteeringInterests,
    WorkPost
}

export default agent;