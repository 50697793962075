import { UseControllerProps, useController } from "react-hook-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { isValid } from "date-fns";
import { Grid } from "@mui/material";
import AppInformationText from "./AppInformationText";
import { CalendarPickerView } from "@mui/lab";

interface Props extends UseControllerProps {
  label: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  views?: CalendarPickerView[];
  opento?: string;
  format?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  information_text?: string;
  disabled?: boolean;
}

const AppDatePicker = (props: Props) => {
  const { field, fieldState } = useController({ ...props });

  return (
    <>
      <Grid container rowSpacing={4}>

        <Grid item xs={11}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              {...props}
              {...field}
              inputFormat="dd/MM/yyyy"
              value={field.value === undefined ? '' : field.value}
              onChange={(newValue) => {
                if (isValid(newValue)) {
                  field.onChange(newValue!, "dd/MM/yyyy");
                }
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  className='rcsiTextField'
                  sx={props.disabled === true ? { backgroundColor: '#f1f1f1', color: '#858282' } : {}}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={1}>
          <AppInformationText information_text={props.information_text} />
        </Grid>

      </Grid>
    </>
  );
};

export default AppDatePicker;
