import { Box, Button, Grid, Typography } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { useEffect, useState } from "react";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { saveRevieweeCommentsAsync } from "../../../../../app/slices/pcsPdpSlice";
import { Link, useHistory, useParams } from "react-router-dom";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { toast } from "react-toastify";
import { RevieweeCommentDto } from "../../../models/revieweeCommentDto";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";
import { useReferenceData } from "../../../../../app/customHooks/useReferenceData";
import { useGetPcsEnrolmentYears } from "../../../../../app/customHooks/useGetPcsEnrolmentYears";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";
import { useGetPcsCurrentYear } from "../../../../../app/customHooks/useGetPcsCurrentYear";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";

export default function Feedback() {

    const { ReviewerComments, PractionerComments } = pcsPlanFormModel.formField;

    const { control, getValues, reset } = useForm({
        resolver: yupResolver(feedbackValidationSchema)
    });
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear | undefined>();
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    useReferenceData();
    useGetPcsEnrolmentYears();
    useGetPcsCurrentYear();

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            setEnrolmentYear(enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [enrolmentYears]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYear) {
            setPcsPdpStatus(pcsPdpStatuses
                .find(x => x.id === enrolmentYear.pcsPdpReviewStatusId));
            reset({
                [ReviewerComments.name]: enrolmentYear.pdpReviewerComments,
                [PractionerComments.name]: enrolmentYear.pdpRevieweeComments
            })
        }
    }, [pcsPdpStatuses, enrolmentYears]);

    const canAddComments = () => {
        return (isCurrentYearPlan() && pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD]);
    }

    const isCurrentYearPlan = () => {
        return enrolmentYear?.year === currentYear;
    }

    const addCommentsAsync = async () => {

        let revieweeCommentDto: RevieweeCommentDto = {
            comments: getValues([PractionerComments.name]).toString(),
            enrolmentYearId: enrolmentYearId
        };
        dispatch(saveRevieweeCommentsAsync(revieweeCommentDto)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                        toast.success(MESSAGE_DETAILS_SAVED);
                    }
                });
            }
        });
    };

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h4'>Feedback</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={ReviewerComments.name}
                        label={ReviewerComments.label}
                        disabled
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={PractionerComments.name}
                        label={PractionerComments.label}
                        information_text_with_label={'Please note these comments are not shared with your reviewer, they are for self-reflection purposes.'}
                    />
                </Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                    <Button component={Link} to={`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}`}>
                        Cancel
                    </Button>
                </Grid>

                {canAddComments() === true &&
                    <Grid item xs={9} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <Button variant="contained" color="primary" onClick={() => addCommentsAsync()}>
                            Add comments
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}