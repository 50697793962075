import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFamOnboardingDetailsAsync } from '../slices/famOnboardingSlice';

export const useGetFamOnboardingDetails = () => {
    const dispatch = useAppDispatch();
    const { famOnboardingDetails, famOnboardingDetailsStatus } = useAppSelector(state => state.famOnboarding);

    useEffect(() => {
        if (famOnboardingDetails === null && famOnboardingDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getFamOnboardingDetailsAsync());
        }
    }, [famOnboardingDetails, famOnboardingDetailsStatus, dispatch]);

    return { famOnboardingDetailsStatus };
};
