import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getAffiliateOnboardingDetailsAsync } from '../slices/affiliateOnboardingSlice';

export const useGetAffiliateOnboardingDetails = () => {
    const dispatch = useAppDispatch();
    const { affiliateOnboardingDetails, affiliateOnboardingDetailsStatus } = useAppSelector(state => state.affiliateOnboardingDetails);

    useEffect(() => {
        if (affiliateOnboardingDetails === null && affiliateOnboardingDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getAffiliateOnboardingDetailsAsync());
        }
    }, [affiliateOnboardingDetails, affiliateOnboardingDetailsStatus, dispatch]);

    return { affiliateOnboardingDetailsStatus };
};
