import { Grid, Typography, Button } from "@mui/material";
import { CountryCodeEnum } from "../../app/enums/countryCodeEnum";
import PaymentUnsuccessful from "../../app/components/PaymentUnsuccessful";
import { MembershipTypeEnum } from "../../app/enums/MembershipTypeEnum";
import { CurrencyCodeIconEnum } from "../../app/enums/currencyCodeIconEnum";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { PaymentRedirectProps } from "../../app/models/membership/paymentRedirectProps";
import { getPaymentSummaryAsync, getPaymentRedirectAsync } from "../../app/slices/paymentSlice";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { getPaymentIdFromBasketAsync } from "../../app/slices/shoppingBasketSlice";
import { enrolBasketAsync } from "../../app/slices/pcsEnrolmentSlice";
import PcsSubscriptionFeesDue from "./PcsSubscriptionFeesDue";
import { AppPathEnum } from "../../app/enums/appPathEnum";

interface Props {
    isCombined: boolean;
}

export default function PaymentSummary({ isCombined }: Props) {
    const dispatch = useAppDispatch();

    const { countries, famMembershipTypes } = useAppSelector(state => state.ReferenceData);
    const { famDetails } = useAppSelector(state => state.famDetails);
    const { basketId, basketIdStatus, canPayMembership } = useAppSelector(state => state.pcsEnrolment);
    const { paymentRedirectStatus, paymentDetails, paymentSummaryStatus, paymentsByPersonStatus } = useAppSelector(state => state.payment);
    const { paymentId, paymentIdStatus } = useAppSelector(state => state.shoppingBasket);

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        dispatch(enrolBasketAsync(isCombined)).then((response) => {
            debugger;
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPaymentIdFromBasketAsync((response.payload as string))).then((paymentIdResponse) => {
                    if (paymentIdResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        dispatch(getPaymentSummaryAsync((paymentIdResponse.payload as string)));
                    }
                });
            }
        });
    }, [dispatch]);

    
    // useEffect(() => {
    //     if (paymentId !== null && paymentIdStatus === ApiRequestStatus.Fulfilled
    //         && paymentDetails === null && paymentSummaryStatus === ApiRequestStatus.Idle) {
    //         dispatch(getPaymentSummaryAsync(paymentId));
    //     }
    // }, [dispatch, paymentId, paymentIdStatus, paymentDetails, paymentSummaryStatus]);

    const paymentOnClickHandler = () => {
        if (paymentId && paymentId !== null) {
            setPageLoading(true);
            let paymentRedirectProps: PaymentRedirectProps = {
                cancelUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${AppPathEnum.PCS_ENROLMENT}/${window.location.search}&cancelPayment=true`,
                paymentId: paymentId,
                successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI)}`
            };
            dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
                setPageLoading(false);
                window.location.replace(paymentRedirectResponse.payload);
            });
        }
    }

    if (pageLoading === true
        || basketIdStatus === ApiRequestStatus.Pending
        || paymentsByPersonStatus === ApiRequestStatus.Pending
        || paymentRedirectStatus === ApiRequestStatus.Pending
        || paymentSummaryStatus === ApiRequestStatus.Pending
        || paymentIdStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        {((new URLSearchParams(window.location.search)).get("cancelPayment") === undefined || (new URLSearchParams(window.location.search)).get("cancelPayment") !== 'true') &&
            <>
                <Grid item xs={12}>
                    <Typography variant='h4'>Payment summary</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={11.5} sm={11.5} sx={{ marginTop: "2%", backgroundColor: "#b9b9b9", padding: "1%" }}>
                    <PcsSubscriptionFeesDue paymentDetails={paymentDetails} />
                </Grid>

                <Grid item xs={11.5} sx={{ fontStyle: 'italic' }}>
                    {(famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.code === MembershipTypeEnum.FELLOW ||
                        famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.code === MembershipTypeEnum.MEMBER) &&
                        countries?.find(x => x.id === famDetails?.countryOfPracticeId)?.alpha3Code === CountryCodeEnum.IRELAND &&
                        <>
                            <Typography variant='subtitle1'>Professional Competence Scheme (PCS) Surgery</Typography>
                            <br></br>
                            <Typography variant='subtitle1'>If you are a Member or Fellow and practice in the Republic of Ireland, you are entitled
                                to a {CurrencyCodeIconEnum.EUR}150 discount on your Professional Competence Scheme (PCS) fee if you pay
                                before 1 July of the current PCS year. *The PCS & Membership Year runs from
                                1st May - 30th April each year.
                            </Typography>
                        </>
                    }
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        Please note that by clicking on the below you will be brought to a
                        third party system for payment processing.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={paymentOnClickHandler}>
                        Pay by card
                    </Button>
                </Grid>

                <Grid item xs={12}></Grid>

            </>
        }

        {(new URLSearchParams(window.location.search)).get("cancelPayment") === 'true' && process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI &&
            <PaymentUnsuccessful retryUrl={process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI} />}
    </>
}