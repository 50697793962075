import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import SubscriptionFeesDue from "./SubscriptionFeesDue";
import MembershipFormModel from "./membershipFormModel";
import PaymentUnsuccessful from "../../components/PaymentUnsuccessful";
import { SpaName } from "../../enums/spaName";
import { MembershipTypeEnum } from "../../enums/MembershipTypeEnum";
import { CountryCodeEnum } from "../../enums/countryCodeEnum";
import { CurrencyCodeIconEnum } from "../../enums/currencyCodeIconEnum";
import { AppPathEnum } from "../../enums/appPathEnum";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useGetCanUseDirectDebitMandate } from "../../customHooks/useGetCanUseDirectDebitMandate";
import { ApiRequestStatus } from "../../enums/apiRequestStatus";
import LoadingComponent from "../../layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, DIRECT_DEBIT_EXISTING_DD_ERROR_RESPONSE, DIRECT_DEBIT_EXISTING_DD_SUCCESS_RESPONSE, MESSAGE_PROCESSING_DETAILS } from "../../utils/constant";
import { payByDDMandateFromBasketAsync } from "../../slices/directDebitMandateSlice";
import { useState } from "react";
import SuccessResponseMessage from "../../components/SuccessResponseMessage";
import { toast } from "react-toastify";

interface Props {
    basketId: string | null;
    retryUrl: string | undefined;
    membershipType: string | undefined;
    paymentOnClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
    SpaName: SpaName;
}

export default function MembershipPaymentSummary(props: Props) {
    const {
        formField: {
            MembershipType,
            MembershipCountry
        }
    } = MembershipFormModel;

    const dispatch = useAppDispatch();
    const history = useHistory();

    const { canUseDirectDebitMandateStatus } = useGetCanUseDirectDebitMandate();

    const { countries, famMembershipTypes } = useAppSelector(state => state.ReferenceData);
    const { famDetails } = useAppSelector(state => state.famDetails);
    const { paymentDetails } = useAppSelector(state => state.payment);
    const { canUseDirectDebitMandate } = useAppSelector(state => state.famDetails);
    const { directDebitPaymentIntentId, directDebitPaymentIntentIdStatus } = useAppSelector(state => state.directDebit);

    const [pageLoading, setPageLoading] = useState(false);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

    const payByExistingDirectDebitAsync = async () => {
        if (directDebitPaymentIntentId === null && directDebitPaymentIntentIdStatus === ApiRequestStatus.Idle) {
            setPageLoading(true);
            dispatch(payByDDMandateFromBasketAsync({ ShoppingBasketId: props.basketId })).then((payByDDMandateFromBasketResponse) => {
                if (payByDDMandateFromBasketResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setIsPaymentSuccessful(true);
                    toast.success(DIRECT_DEBIT_EXISTING_DD_SUCCESS_RESPONSE);
                }
                else {
                    setIsPaymentSuccessful(false);
                    toast.error(DIRECT_DEBIT_EXISTING_DD_ERROR_RESPONSE);
                }
                setPageLoading(false);
            });
        }
    }

    const payByDirectDebitAsync = async () => {
        let previousSpa = "";

        if (props.SpaName === SpaName.AFFILIATE) {
            previousSpa = AppPathEnum.AFFILIATE_ASSOCIATE;
        }
        else if (props.SpaName === SpaName.MEMBER_ONBOARDING) {
            previousSpa = AppPathEnum.MEMBERSHIP_ONBOARDING;
        }
        else if (props.SpaName === SpaName.MEMBER_TRANSFER) {
            previousSpa = AppPathEnum.MEMBERSHIP_TRANSFER;
        }
        const queryParams = new URLSearchParams(window.location.search);
        const cancelUrl = queryParams.get("cancelUrl");
        const basketIdParam = queryParams.get("basketId");
        let urlToRedirect = '';

        if ((!cancelUrl || cancelUrl === null) && (!basketIdParam || basketIdParam === null)) {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&cancelUrl=${previousSpa}&basketId=${props.basketId}`;
        }
        else if (!cancelUrl || cancelUrl === null) {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&cancelUrl=${previousSpa}`;
        }
        else if (!basketIdParam || basketIdParam === null) {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&basketId=${props.basketId}`;
        }
        else {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}`;
        }

        history.push(urlToRedirect);
    }

    if (isPaymentSuccessful === true) {
        return <SuccessResponseMessage header={'Thank you'} body={DIRECT_DEBIT_EXISTING_DD_SUCCESS_RESPONSE} />
    }

    if (pageLoading === true || canUseDirectDebitMandateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                {((new URLSearchParams(window.location.search)).get("cancelPayment") === undefined || (new URLSearchParams(window.location.search)).get("cancelPayment") !== 'true') &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Payment summary</Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={6} md={4} sm={3} sx={{
                            color: 'black', textAlign: 'start',
                            marginBottom: '3px', paddingTop: '1.5% !important', paddingBottom: '1.5%'
                        }}>
                            <b>{MembershipType.label}</b>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1}></Grid>

                        <Grid item xs={5} md={7} sm={8} sx={{ paddingTop: '1.5% !important', paddingBottom: '1.5%' }}>
                            {props.membershipType}
                        </Grid>

                        <Grid item xs={6} md={4} sm={3} sx={{
                            color: 'black', textAlign: 'start',
                            marginBottom: '3px', paddingTop: '1.5% !important', paddingBottom: '1.5%'
                        }}>
                            <b>{MembershipCountry.label}</b>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1}></Grid>

                        <Grid item xs={5} md={7} sm={8} sx={{ paddingTop: '1.5% !important', paddingBottom: '1.5%' }}>
                            {famDetails?.countryOfPracticeId === null ? '' : countries?.find(x => x.id ===
                                famDetails?.countryOfPracticeId)?.value}
                        </Grid>

                        <Grid item xs={11.5} sm={11.5} sx={{ marginTop: "2%", backgroundColor: "#b9b9b9", padding: "1%" }}>
                            <SubscriptionFeesDue paymentDetails={paymentDetails} />
                        </Grid>

                        <Grid item xs={11.5} sx={{ fontStyle: 'italic' }}>
                            {(famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.code === MembershipTypeEnum.FELLOW ||
                                famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.code === MembershipTypeEnum.MEMBER) &&
                                countries?.find(x => x.id === famDetails?.countryOfPracticeId)?.alpha3Code === CountryCodeEnum.IRELAND &&
                                <>
                                    <Typography variant='subtitle1'>Professional Competence Scheme (PCS) Surgery</Typography>
                                    <br></br>
                                    <Typography variant='subtitle1'>If you are a Member or Fellow and practice in the Republic of Ireland, you are entitled
                                        to a {CurrencyCodeIconEnum.EUR}150 discount on your Professional Competence Scheme (PCS) fee if you pay
                                        before 1 July of the current PCS year. *The PCS & Membership Year runs from
                                        1st May - 30th April each year.
                                    </Typography>
                                </>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Please note that by clicking on the below you will be brought to a
                                third party system for payment processing.
                            </Typography>
                        </Grid>

                        {props.SpaName !== SpaName.AFFILIATE &&
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    Please note: If you sign up to recurring payments, your annual membership subscriptions
                                    will be taken automatically every year. This will maintain your 'In Good Standing' status.
                                </Typography>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={props.paymentOnClickHandler}>
                                Pay by card
                            </Button>
                        </Grid>

                        {props.SpaName !== SpaName.AFFILIATE && canUseDirectDebitMandate !== null && canUseDirectDebitMandate === false &&
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={payByDirectDebitAsync}>
                                    Pay by direct debit
                                </Button>
                            </Grid>
                        }

                        {props.SpaName !== SpaName.AFFILIATE && canUseDirectDebitMandate !== null && canUseDirectDebitMandate === true &&
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={payByExistingDirectDebitAsync}>
                                    Pay by existing direct debit
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12}></Grid>

                    </>
                }

                {(new URLSearchParams(window.location.search)).get("cancelPayment") === 'true' && props.retryUrl && <PaymentUnsuccessful retryUrl={props.retryUrl} />}

            </Grid>
        </Box >
    </>
}