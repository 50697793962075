import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getActivitiesAcrossDomainsAsync } from '../slices/pcsPdpSlice';

export const useGetActivitiesAcrossDomains = () => {
    const dispatch = useAppDispatch();
    const { activitiesAcrossDomains, planStateStatus } = useAppSelector(state => state.pcsPdp);

    useEffect(() => {
        if (!activitiesAcrossDomains && planStateStatus === ApiRequestStatus.Idle) {
            dispatch(getActivitiesAcrossDomainsAsync());
        }
    }, [activitiesAcrossDomains, planStateStatus, dispatch]);

    return { planStateStatus };
};
