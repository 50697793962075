import { Box, Paper, Grid, Typography } from "@mui/material";

interface Prop {
    reenrol: boolean;
}

export default function Header({ reenrol }: Prop) {

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>
                        {reenrol === true ? 'PCS Enrolment' : 'PCS Registration'}
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        Please enter your registration details.
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
