import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCurrentMembershipType } from '../slices/famSlice';

export const useGetCurrentMembershipType = () => {
    const dispatch = useAppDispatch();
    const { currentMembershipType, currentMembershipTypeStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (currentMembershipType === null && currentMembershipTypeStatus === ApiRequestStatus.Idle) {
            dispatch(getCurrentMembershipType());
        }
    }, [currentMembershipType, currentMembershipTypeStatus, dispatch]);

    return { currentMembershipTypeStatus };
};
