import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { EducationalDetailsDto } from "../models/person/educationalDetailsDto";
import { UpdateEducationCommand } from "../models/person/updateEducationCommand";

interface EducationalDetailsState {
    educationalDetails: EducationalDetailsDto | null;

    educationalDetailsStatus: ApiRequestStatus;
    saveEducationalDetailsStatus: ApiRequestStatus;
    updateEducationalDetailsStatus: ApiRequestStatus;
}

const initialState: EducationalDetailsState = {
    educationalDetails: null,

    educationalDetailsStatus: ApiRequestStatus.Idle,
    saveEducationalDetailsStatus: ApiRequestStatus.Idle,
    updateEducationalDetailsStatus: ApiRequestStatus.Idle
}

export const getEducationAsync = createAsyncThunk<EducationalDetailsDto>(
    'pmd/getEducationAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Pmd.getEducationAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveEducationAsync = createAsyncThunk<void, EducationalDetailsDto>(
    'pmd/saveEducationAsync',
    async (educationalDetails, thunkAPI) => {
        try {
            return agent.Pmd.saveEducationAsync(educationalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateEducationAsync = createAsyncThunk<void, UpdateEducationCommand>(
    'pmd/updateEducationAsync',
    async (educationalDetails, thunkAPI) => {
        try {
            return agent.Pmd.updateEducationAsync(educationalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

const pmdSlice = createSlice({
    name: 'pmdSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getEducationAsync.pending, (state) => {
            state.educationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getEducationAsync.fulfilled, (state, action) => {
            state.educationalDetailsStatus = ApiRequestStatus.Fulfilled;
            state.educationalDetails = action.payload;
        });
        builder.addCase(getEducationAsync.rejected, (state) => {
            state.educationalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveEducationAsync.pending, (state) => {
            state.saveEducationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveEducationAsync.fulfilled, (state) => {
            state.saveEducationalDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveEducationAsync.rejected, (state) => {
            state.saveEducationalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateEducationAsync.pending, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateEducationAsync.fulfilled, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateEducationAsync.rejected, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})

export default pmdSlice;