import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFamSubscriptionsAsync } from '../slices/famSlice';

export const useGetFamSubscriptions = () => {
    const dispatch = useAppDispatch();
    const { famMembershipSubscriptions, famSubscriptionsStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (famMembershipSubscriptions === null && famSubscriptionsStatus === ApiRequestStatus.Idle) {
            dispatch(getFamSubscriptionsAsync());
        }
    }, [famMembershipSubscriptions, famSubscriptionsStatus, dispatch]);

    return { famSubscriptionsStatus };
};
