import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PaymentRedirectProps } from "../models/membership/paymentRedirectProps";
import { PaymentDto } from "../models/payment/paymentDto";

interface paymentState {
    paymentRedirectDetails: string | null;
    paymentDetails: PaymentDto | null;
    paymentsByPerson: PaymentDto[] | null;
    heyCentricUrl: string | null;

    paymentRedirectStatus: ApiRequestStatus;
    paymentSummaryStatus: ApiRequestStatus;
    paymentsByPersonStatus: ApiRequestStatus;
    selfServiceStatus: ApiRequestStatus;
}

const initialState: paymentState = {
    paymentRedirectDetails: null,
    paymentDetails: null,
    paymentsByPerson: null,
    heyCentricUrl: null,

    paymentRedirectStatus: ApiRequestStatus.Idle,
    paymentSummaryStatus: ApiRequestStatus.Idle,
    paymentsByPersonStatus: ApiRequestStatus.Idle,
    selfServiceStatus: ApiRequestStatus.Idle
}

export const getPaymentRedirectAsync = createAsyncThunk<string, PaymentRedirectProps>(
    'paymentSlice/getPaymentRedirectAsync',
    async (paymentRedirectProps, thunkAPI) => {
        try {
            return agent.Payment.getPaymentRedirectAsync(paymentRedirectProps);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPaymentSummaryAsync = createAsyncThunk<PaymentDto, string>(
    'paymentSlice/getPaymentSummaryAsync',
    async (paymentId, thunkAPI) => {
        try {
            return agent.Payment.getPaymentByPaymentIdAsync(paymentId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPaymentsAsync = createAsyncThunk<PaymentDto[]>(
    'paymentSlice/getPaymentsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Payment.getPaymentsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getSelfServiceAsync = createAsyncThunk<string>(
    'paymentSlice/getSelfServiceAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Payment.getSelfServiceAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const paymentSlice = createSlice({
    name: 'paymentSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPaymentRedirectAsync.pending, (state) => {
            state.paymentRedirectStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPaymentRedirectAsync.fulfilled, (state, action) => {
            state.paymentRedirectDetails = action.payload;
            state.paymentRedirectStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getPaymentRedirectAsync.rejected, (state) => {
            state.paymentRedirectStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getPaymentSummaryAsync.pending, (state) => {
            state.paymentSummaryStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPaymentSummaryAsync.fulfilled, (state, action) => {
            state.paymentDetails = action.payload;
            state.paymentSummaryStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getPaymentSummaryAsync.rejected, (state) => {
            state.paymentSummaryStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getPaymentsAsync.pending, (state) => {
            state.paymentsByPersonStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPaymentsAsync.fulfilled, (state, action) => {
            state.paymentsByPerson = action.payload;
            state.paymentsByPersonStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getPaymentsAsync.rejected, (state) => {
            state.paymentsByPersonStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getSelfServiceAsync.pending, (state) => {
            state.selfServiceStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getSelfServiceAsync.fulfilled, (state, action) => {
            state.heyCentricUrl = action.payload;
            state.selfServiceStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getSelfServiceAsync.rejected, (state) => {
            state.selfServiceStatus = ApiRequestStatus.Rejected;
        });
    })
})
