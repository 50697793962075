import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFamTransferDetailsAsync } from '../slices/famTransferSlice';

export const useGetFamTransferDetails = () => {
    const dispatch = useAppDispatch();
    const { famTransferDetails, famTransferDetailsStatus } = useAppSelector(state => state.famTransfer);

    useEffect(() => {
        if (famTransferDetails === null && famTransferDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getFamTransferDetailsAsync());
        }
    }, [famTransferDetails, famTransferDetailsStatus, dispatch]);

    return { famTransferDetailsStatus };
};
