import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { VolunteeringInterestDto } from './common/volunteeringInterestDto';
import { VolunteeringStepsEnum } from './common/volunteeringStepsEnum';
import { VolunteeringValidationSchemas } from './common/volunteeringValidationSchemas';
import VolunteeringPersonalDetails from './VolunteeringPersonalDetails';
import VolunteeringInterests from './VolunteeringInterests';
import { VolunteeringTypeEnum } from './common/volunteeringTypeEnum';
import SuccessResponseMessage from '../../components/SuccessResponseMessage';
import { ApiRequestStatus } from '../../enums/apiRequestStatus';
import LoadingComponent from '../../layout/LoadingComponent';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../slices/personSlice';
import { getVolunteeringInterestsAsync, updateVolunteeringInterestsAsync } from '../../slices/volunteeringSlice';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_NEXT, MESSAGE_SAVE_AND_CONTINUE,
  MESSAGE_SUBMIT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NOT_A_VALUE, MESSAGE_PROCESSING_DETAILS
} from '../../utils/constant';
import { SwitchButtonItem } from '../common/switchButtonItem';
import { PersonalDetailsDto } from '../person/personalDetailsDto';
import { AlumniVolunteeringHeader } from '../../../features/alumniVolunteering/AlumniVolunteeringHeader';
import { MembershipVolunteeringHeader } from '../../../features/membershipVolunteering/MembershipVolunteeringHeader';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../enums/userClaims';
import { useGetPersonalDetails } from '../../customHooks/useGetPersonalDetails';
import { useGetVolunteeringInterests } from '../../customHooks/useGetVolunteeringInterests';
import { useReferenceData } from '../../customHooks/useReferenceData';

interface Props {
  type: VolunteeringTypeEnum;
}

export default function VolunteeringForm({ type }: Props) {

  const steps = [VolunteeringStepsEnum.VolunteeringInterests, VolunteeringStepsEnum.PersonalDetails];

  const alumniCountryInfoText =
    `Updating your country of practice ensures that you will be only asked to participate in volunteering opportunities relevant to you.`;
  const memberCountryInfoText = `Updating your country of practice ensures that you will be only asked to participate 
  in volunteering opportunities relevant to you. Please note that this is also used to determine your Membership subscription rate.`;

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <VolunteeringInterests
          volunteeringInterestTypes={type === VolunteeringTypeEnum.ALUMNI ?
            alumniVolunteeringInterestTypes : famVolunteeringInterestTypes}
          volunteeringInterests={volunteeringInterests} />
      case 1:
        return <VolunteeringPersonalDetails
          countryInfoText={type === VolunteeringTypeEnum.ALUMNI ?
            alumniCountryInfoText : memberCountryInfoText}
          disciplines={disciplines}
          areasOfPractice={areasOfPractice} personalDetails={personalDetails}
          countries={countries} countrySubdivisions={countrySubdivisions} />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VolunteeringValidationSchemas[activeStep])
  });

  const { trigger } = methods;
  const formObj = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { countries, countrySubdivisions, disciplines, areasOfPractice, alumniVolunteeringInterestTypes,
    famVolunteeringInterestTypes } = useAppSelector(state => state.ReferenceData);
  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { volunteeringInterests, updateVolunteeringInterestsStatus } = useAppSelector(state => state.volunteeringInterests);

  const [pageLoading, setPageLoading] = useState(false);

  const { staticDataStateStatus } = useReferenceData();
  const { personalDetailsStatus } = useGetPersonalDetails();
  const { volunteeringInterestsStatus } = useGetVolunteeringInterests();

  function MapToPersonalDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      mobile: formObj.PreferredPhoneNumber,
      disciplineId: formObj.Discipline,
      areaOfPracticeId: formObj.AreaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE ? '' : formObj.AreaOfPracticeMoreDetails,
      locationCountryId: formObj.LocationCountry,
      locationCountrySubdivisionId: formObj.CountrySubdivision === MESSAGE_NOT_A_VALUE ? '' : formObj.CountrySubdivision
    }
    return data;
  }

  function MapToVolunteeringInterests() {
    const interests: VolunteeringInterestDto[] = [];

    if (formObj.VolunteeringInterests) {
      const interestSwitchButtonItems: SwitchButtonItem[] = formObj.VolunteeringInterests as SwitchButtonItem[];

      interestSwitchButtonItems.forEach((item) => {
        let volunteeringInterestComment = (item.checked === true ?
          (formObj['Comments-' + item.id] === undefined ?
            '' : formObj['Comments-' + item.id])
          : '');
        interests.push({
          volunteeringInterestTypeId: item.id,
          comment: volunteeringInterestComment,
          optedIn: item.checked
        });
      });
    }
    else if (!formObj.VolunteeringInterests && volunteeringInterests && volunteeringInterests.length > 0) {
      alumniVolunteeringInterestTypes?.map((item) => {
        const volunteeringInterest = volunteeringInterests.find(x => x.volunteeringInterestTypeId === item.id);
        interests.push({
          comment: !volunteeringInterest ? '' : volunteeringInterest.comment,
          optedIn: !volunteeringInterest ? false : volunteeringInterest.optedIn,
          volunteeringInterestTypeId: !volunteeringInterest ? '' : volunteeringInterest.volunteeringInterestTypeId
        });
      });
    }
    else if (!formObj.VolunteeringInterests && volunteeringInterests && volunteeringInterests?.length <= 0 && type === VolunteeringTypeEnum.ALUMNI) {
      alumniVolunteeringInterestTypes?.map((item) => {
        interests.push({
          comment: '',
          optedIn: false,
          volunteeringInterestTypeId: item.id
        });
      });
    }
    else if (!formObj.VolunteeringInterests && volunteeringInterests && volunteeringInterests?.length <= 0 && type === VolunteeringTypeEnum.MEMBER) {
      famVolunteeringInterestTypes?.map((item) => {
        interests.push({
          comment: '',
          optedIn: false,
          volunteeringInterestTypeId: item.id
        });
      });
    }

    return interests;
  }

  const saveVolunteeringInterests = (volunteeringInterestDto: VolunteeringInterestDto[]) => {
    setPageLoading(true);
    dispatch(updateVolunteeringInterestsAsync(volunteeringInterestDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        toast.success(MESSAGE_DETAILS_SAVED);
        dispatch(getVolunteeringInterestsAsync()).then(() => {
          setPageLoading(false);
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        });
      }
      else {
        setPageLoading(false);
      }
    });
  }

  const savePersonalDetails = (personalDetailsDto: PersonalDetailsDto) => {
    setPageLoading(true);
    dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPersonalDetailsAsync(personId)).then(() => {
          toast.success(MESSAGE_DETAILS_SAVED);
          setPageLoading(false);
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        });
      } else {
        setPageLoading(false);
      }
    });
  }

  const handleNext = async () => {

    const isStepValid = await trigger();

    if (isStepValid) {
      switch (activeStep) {
        case 0:
          saveVolunteeringInterests(MapToVolunteeringInterests());
          break;
        case 1:
          savePersonalDetails(MapToPersonalDetails());
          break;
        default:
          return "not a valid step";
      }
    }
  }

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || personalDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || volunteeringInterestsStatus === ApiRequestStatus.Pending
    || updateVolunteeringInterestsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>

      {activeStep !== steps.length && (type === VolunteeringTypeEnum.ALUMNI ? <AlumniVolunteeringHeader /> : <MembershipVolunteeringHeader />)}

      <br></br>
      <>
        {activeStep !== steps.length && <Stepper activeStep={activeStep} sx={{ marginTop: "2%" }} orientation={isMobile === true ? "vertical" : "horizontal"}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>}

        <br></br>

        {activeStep > 0 && activeStep !== steps.length &&
          <Button variant="contained" onClick={_handleBack}>
            {MESSAGE_BACK_TO_PREVIOUS_STEP}
          </Button>
        }

        {activeStep === steps.length ? (
          <SuccessResponseMessage header={'Thank you'}
            body={`Your volunteering preferences and details have been saved successfully.
            You can change your saved volunteering preferences at any time, by accessing this form.`} />
        ) : (
          <>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (activeStep === 0 ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT)}
                  </Button>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
    </FormProvider >
  );
};
