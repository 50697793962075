import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { creditsSlice } from "../slices/creditsSlice";
import { referenceDataSlice } from "../slices/referenceDataSlice";
import { personSlice } from "../slices/personSlice";
import { pcsPdpSlice } from "../slices/pcsPdpSlice";
import { alumniSlice } from "../slices/alumniSlice";
import { affiliateOnboardingSlice } from "../slices/affiliateOnboardingSlice";
import { famTransferSlice } from "../slices/famTransferSlice";
import { famOnboardingSlice } from "../slices/famOnboardingSlice";
import { volunteeringSlice } from "../slices/volunteeringSlice";
import { famSlice } from "../slices/famSlice";
import { paymentSlice } from "../slices/paymentSlice";
import { famEnrolmentSlice } from "../slices/famEnrolmentSlice";
import { donationsSlice } from "../slices/donationsSlice";
import { requestSlice } from "../slices/requestSlice";
import { directDebitMandateSlice } from "../slices/directDebitMandateSlice";
import { cardSubscriptionSlice } from "../slices/cardSubscriptionSlice";
import { workpostSlice } from "../slices/workpostSlice";
import { pcsEnrolmentSlice } from "../slices/pcsEnrolmentSlice";
import { pcsSlice } from "../slices/pcsSlice";
import pmdSlice from "../slices/pmdSlice";
import { shoppingBasketSlice } from "../slices/shoppingBasketSlice";

export const store = configureStore({
    reducer: {
        affiliateOnboardingDetails: affiliateOnboardingSlice.reducer,
        alumniDetails: alumniSlice.reducer,
        cardSubscription: cardSubscriptionSlice.reducer,
        credits: creditsSlice.reducer,
        directDebit: directDebitMandateSlice.reducer,
        donations: donationsSlice.reducer,

        famDetails: famSlice.reducer,
        famEnrolment: famEnrolmentSlice.reducer,
        famOnboarding: famOnboardingSlice.reducer,
        famTransfer: famTransferSlice.reducer,
        payment: paymentSlice.reducer,
        pcsPdp: pcsPdpSlice.reducer,
        pcs: pcsSlice.reducer,
        pcsEnrolment: pcsEnrolmentSlice.reducer,
        personalDetail: personSlice.reducer,
        pmd: pmdSlice.reducer,
        ReferenceData: referenceDataSlice.reducer,
        requests: requestSlice.reducer,
        shoppingBasket: shoppingBasketSlice.reducer,
        volunteeringInterests: volunteeringSlice.reducer,
        workpost: workpostSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;