import 'react-toastify/dist/ReactToastify.css';
import { MsalProvider } from "@azure/msal-react";
import './styles.css';
import { Pages } from './Pages';

function App({ msalInstance }: any) {
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Pages />
      </MsalProvider>
    </>
  );
}

export default App;