import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCardSubscriptionAsync } from '../slices/cardSubscriptionSlice';

export const useGetCardSubscriptions = () => {
    const dispatch = useAppDispatch();
    const { subscriptions, subscriptionsStatus } = useAppSelector(state => state.cardSubscription);

    useEffect(() => {
        if (subscriptions === null && subscriptionsStatus === ApiRequestStatus.Idle) {
            dispatch(getCardSubscriptionAsync());
        }
    }, [subscriptions, subscriptionsStatus, dispatch]);

    return { subscriptionsStatus };
};
