import { PDFDownloadLink } from "@react-pdf/renderer";
import { Grid } from "@mui/material";
import PdfDocument from "./PdfDocument";
import { formatDateWithMonthName } from "../../../app/utils/util";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/store/configureStore";
import { PaymentStatus } from "../../../app/enums/paymentStatus";
import { CurrencyCodeEnum } from "../../../app/enums/currencyCodeEnum";
import { PaymentDto } from "../../../app/models/payment/paymentDto";
import { useGetPayments } from "../../../app/customHooks/useGetPayments";
import { useGetPersonalDetails } from "../../../app/customHooks/useGetPersonalDetails";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";

export default function MyReceipts() {
    const { staticDataStateStatus } = useReferenceData();
    const { personalDetailsStatus } = useGetPersonalDetails();
    const { paymentsByPersonStatus } = useGetPayments();

    const { titles, countries, funds, currencies, paymentMethods, paymentStatuses } = useAppSelector(state => state.ReferenceData);
    const { paymentsByPerson } = useAppSelector(state => state.payment);
    const { personalDetails } = useAppSelector(state => state.personalDetail);

    const [payments, setPayments] = useState<PaymentDto[]>();

    useEffect(() => {
        const payments = paymentsByPerson?.filter(x => x.currencyId !== currencies?.find(y => y.code === CurrencyCodeEnum.USD)?.id &&
            x.paymentStatusId === paymentStatuses?.find(x => x.code === PaymentStatus.Paid)?.id)
            .sort(function (a, b) {
                return (b.paymentDate !== null ? new Date(b.paymentDate).getTime() : 0) - (a.paymentDate !== null ? new Date(a.paymentDate).getTime() : 0);
            });
        setPayments(payments)
    }, [paymentsByPerson]);

    if (staticDataStateStatus === ApiRequestStatus.Pending ||
        personalDetailsStatus === ApiRequestStatus.Pending ||
        paymentsByPersonStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={4} sx={{ textAlign: 'center' }}><b>File to download</b></Grid>

                <Grid item xs={4} sx={{ textAlign: 'center' }}><b>Payment date</b></Grid>

                <Grid item xs={4} sx={{ textAlign: 'center' }}><b>Reference</b></Grid>

                {payments?.map((paymentDetails) => (
                    <React.Fragment key={paymentDetails.id}>
                        <Grid item xs={4} sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                            <PDFDownloadLink document={<PdfDocument titles={titles} countries={countries} funds={funds}
                                currencies={currencies} personalDetails={personalDetails} paymentDetails={paymentDetails}
                                paymentMethods={paymentMethods} />}
                                fileName="My receipt.pdf">
                                {({ loading }) =>
                                    loading === true ? 'Loading document...' :
                                        <img src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/pdf-icon.png`} style={{ width: '20%' }} />}
                            </PDFDownloadLink>
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                            {paymentDetails.paymentDate !== null ? formatDateWithMonthName(new Date(paymentDetails.paymentDate)) : ''}
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: 'center', wordWrap: 'break-word' }}>{paymentDetails.reference}</Grid>
                    </React.Fragment>
                ))}

                {(!payments || payments?.length === 0) &&
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        No receipts found.
                    </Grid>
                }
            </Grid>

            <Grid item xs={12}></Grid>
        </>
    )
}