import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getVolunteeringInterestsAsync } from '../slices/volunteeringSlice';

export const useGetVolunteeringInterests = () => {
    const dispatch = useAppDispatch();
    const { volunteeringInterests, volunteeringInterestsStatus } = useAppSelector(state => state.volunteeringInterests);

    useEffect(() => {
        if (volunteeringInterests === null && volunteeringInterestsStatus === ApiRequestStatus.Idle) {
            dispatch(getVolunteeringInterestsAsync());
        }
    }, [volunteeringInterests, volunteeringInterestsStatus, dispatch]);

    return { volunteeringInterestsStatus };
};
