import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AppDateTimePicker from "../../app/components/AppDateTimePicker";
import { addDays } from "../../app/utils/util";
import RequestFormModel from "./models/requestFormModel";
import { VolunteerRequestValidationSchema } from "./models/requestValidationSchemas";
import AppTextInput from "../../app/components/AppTextInput";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useEffect, useState } from "react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_NOT_A_VALUE, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import { MapAreaOfPracticesToAutocompleteItems, MapDisciplinesToAutocompleteItems, MapVolunteeringInterestTypesToAutocompleteItems } from "../../app/utils/convertStaticDataToDropdownItems";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import SuccessResponse from "./models/SuccessResponse";
import { useReferenceData } from "../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import AppAutocomplete from "../../app/components/AppAutocomplete";
import { MapCountriesToAutocompleteItems } from "../../app/utils/convertStaticDataToAutocompleteItems";
import { AutocompleteItem } from "../../app/models/common/autocompleteItem";

export default function VolunteerRequest() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(VolunteerRequestValidationSchema)
    });
    const { control, trigger, reset, getValues, setValue, watch, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        VolunteerRequest: {
            CurrentRole,
            DepartmentIfFacultyOrSocietyClubIfStudent,
            TypeOfVolunteeringRequired,
            NumberOfVolunteersRequired,
            DateAndTimeOfVolunteering,
            DurationOfVolunteeringInHours,
            IsVolunteeringOnlineInPerson,
            Discipline,
            AreaOfPractice,
            CareerRange,
            Location,
            WhatAreYouAskingThemToDoDeliverOn,
            AnyAdditonalInfo,
            ReferenceForVolunteringJob
        }
    } = RequestFormModel;

    const dispatch = useAppDispatch();

    const { countries, areasOfPractice, disciplines, alumniVolunteeringInterestTypes } = useAppSelector(state => state.ReferenceData);
    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const { staticDataStateStatus } = useReferenceData();

    useEffect(() => {
        if (!areasOfPractice || areasOfPractice?.length === 0) {
            setValue(AreaOfPractice.name, MESSAGE_NOT_A_VALUE);
        }
    }, [areasOfPractice, reset]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (name === Discipline.name && type === 'change') {
                if (areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name))) {
                    setValue(AreaOfPractice.name, '');
                }
                else {
                    setValue(AreaOfPractice.name, MESSAGE_NOT_A_VALUE);
                }
            }
        });
    }, [watch]);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.VolunteeringRequest.toString(),
            details: `
            ${CurrentRole.label}: ${formObj.CurrentRole},
            ${DepartmentIfFacultyOrSocietyClubIfStudent.label}: ${formObj.DepartmentIfFacultyOrSocietyClubIfStudent},
            ${TypeOfVolunteeringRequired.label}: ${alumniVolunteeringInterestTypes?.find(x => x.id === formObj.TypeOfVolunteeringRequired)?.value},
            ${NumberOfVolunteersRequired.label}: ${formObj.NumberOfVolunteersRequired},
            ${DateAndTimeOfVolunteering.label}: ${formObj.DateAndTimeOfVolunteering},
            ${DurationOfVolunteeringInHours.label}: ${formObj.DurationOfVolunteeringInHours},
            ${IsVolunteeringOnlineInPerson.label}: ${formObj.IsVolunteeringOnlineInPerson},
            ${AreaOfPractice.label}: ${disciplines?.find(x => x.id === formObj.AreaOfPractice)?.value},
            ${CareerRange.label}: ${formObj.CareerRange},
            ${Location.label}: ${countries?.find(x => x.id === formObj.Location)?.value},
            ${WhatAreYouAskingThemToDoDeliverOn.label}: ${formObj.WhatAreYouAskingThemToDoDeliverOn},
            ${AnyAdditonalInfo.label}: ${formObj.AnyAdditonalInfo},
            ${ReferenceForVolunteringJob.label}: ${formObj.ReferenceForVolunteringJob}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = 'Thank you for contacting us. A member of the team will process your request shortly.';

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [CurrentRole.name]: '',
                            [DepartmentIfFacultyOrSocietyClubIfStudent.name]: '',
                            [TypeOfVolunteeringRequired.name]: '',
                            [NumberOfVolunteersRequired.name]: '',
                            [DateAndTimeOfVolunteering.name]: '',
                            [DurationOfVolunteeringInHours.name]: '',
                            [IsVolunteeringOnlineInPerson.name]: '',
                            [AreaOfPractice.name]: '',
                            [CareerRange.name]: '',
                            [Location.name]: '',
                            [WhatAreYouAskingThemToDoDeliverOn.name]: '',
                            [AnyAdditonalInfo.name]: '',
                            [ReferenceForVolunteringJob.name]: ''
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    const CurrentRoles: AutocompleteItem[] = [
        { id: 'Student', label: 'Student', code: 'Student' },
        { id: 'Faculty', label: 'Faculty', code: 'Faculty' }
    ];

    const VolunteerMode: AutocompleteItem[] = [
        { id: 'Online', label: 'Online', code: 'Online' },
        { id: 'InPerson', label: 'In person', code: 'InPerson' },
        { id: 'Hybrid', label: 'Hybrid', code: 'Hybrid' }
    ];

    const CareerRanges: AutocompleteItem[] = [
        { id: 'EarlyStage', label: 'Early stage', code: 'EarlyStage' },
        { id: 'MidStage', label: 'Mid stage', code: 'MidStage' },
        { id: 'LateStage', label: 'Late stage', code: 'LateStage' }
    ];

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Volunteering request</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        We have recruited alumni to volunteer in a range of different roles –
                        from guest speakers to content creators to event organisers. If you are
                        planning an activity and would like to invite a specific member of the
                        alumni community to volunteer, or you would like the assistance of the
                        Alumni Team to identify an alumni volunteer, then please complete the
                        form below.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ width: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Your details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={CurrentRole.name} label={CurrentRole.label} items={CurrentRoles} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={DepartmentIfFacultyOrSocietyClubIfStudent.name} label={DepartmentIfFacultyOrSocietyClubIfStudent.label} />
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box>

        <Box sx={{ width: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Volunteering ask</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={TypeOfVolunteeringRequired.name}
                        label={TypeOfVolunteeringRequired.label}
                        items={MapVolunteeringInterestTypesToAutocompleteItems(
                            alumniVolunteeringInterestTypes?.filter(x => x.showInPortal === true) ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={NumberOfVolunteersRequired.name} label={NumberOfVolunteersRequired.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppDateTimePicker control={control} name={DateAndTimeOfVolunteering.name} label={DateAndTimeOfVolunteering.label}
                        minDate={addDays(new Date(), 0)} maxDate={addDays(new Date(), 10)} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={DurationOfVolunteeringInHours.name} label={DurationOfVolunteeringInHours.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={IsVolunteeringOnlineInPerson.name}
                        label={IsVolunteeringOnlineInPerson.label} items={VolunteerMode} />
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box>

        <Box sx={{ width: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Volunteer details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={Discipline.name} label={Discipline.label}
                        items={MapDisciplinesToAutocompleteItems(disciplines ?? [])}
                    />
                </Grid>

                {areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) &&
                    areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)).length > 0 &&
                    <Grid item xs={12}>
                        <AppAutocomplete control={control} name={AreaOfPractice.name}
                            label={AreaOfPractice.label}
                            items={MapAreaOfPracticesToAutocompleteItems(areasOfPractice?.filter(
                                x => x.disciplineId === getValues(Discipline.name)) ?? [])}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={CareerRange.name}
                        label={CareerRange.label} items={CareerRanges} />
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={Location.name} label={Location.label}
                        items={MapCountriesToAutocompleteItems(countries ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={WhatAreYouAskingThemToDoDeliverOn.name} label={WhatAreYouAskingThemToDoDeliverOn.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={AnyAdditonalInfo.name} label={AnyAdditonalInfo.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={ReferenceForVolunteringJob.name} label={ReferenceForVolunteringJob.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}