import { Box, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import AppTextInput from '../../app/components/AppTextInput';
import { useAppSelector } from '../../app/store/configureStore';
import AppAutocomplete from '../../app/components/AppAutocomplete';
import { MapCountriesToAutocompleteItems, MapInstitutionsToAutocompleeItems } from '../../app/utils/convertStaticDataToAutocompleteItems';
import AppTextareaInput from '../../app/components/AppTextareaInput';
import { AutocompleteItem } from '../../app/models/common/autocompleteItem';
import { Institution } from '../../app/models/staticData/institution';

export default function EducationalDetails() {

  const methods = useFormContext();
  const { control, getValues, reset, watch } = methods;

  const {
    personalDetailsFormField: {
      DOB
    },
    educationalDetailsFormField: {
      QualificationCountry,
      PrimaryMedicalDegreeCollege,
      OtherPrimaryMedicalDegreeCollege,
      QualificationYear,
      OtherQualifications
    } } = registrationFormModel;

  const { countries, educationInstitutions } = useAppSelector(state => state.ReferenceData);
  const { personalDetails } = useAppSelector(state => state.personalDetail);
  const { registrationDetails } = useAppSelector(state => state.pcs);

  const [filteredInstitutions, setFilteredInstitutions] = useState<Institution[]>();

  useEffect(() => {
    if (getValues([QualificationCountry.name]).toString() !== "" &&
      educationInstitutions && educationInstitutions.length > 0) {
      setFilteredInstitutions(educationInstitutions.filter(x => x.countryId === getValues([QualificationCountry.name]).toString()));
    }
  }, [educationInstitutions, getValues]);

  useEffect(() => {
    watch((_, { name, type }) => {
      if (educationInstitutions && name === QualificationCountry.name && type === 'change') {
        if (getValues(QualificationCountry.name) === '') {
          setFilteredInstitutions(educationInstitutions.filter(x => x.countryId === getValues([QualificationCountry.name]).toString()));
        }
      }
    });
  }, [QualificationCountry.name, educationInstitutions, filteredInstitutions, watch, getValues]);

  const getQualificationYears = (startYear: number) => {
    let lastYear: number = new Date().getFullYear();
    let years: AutocompleteItem[] = [];

    while (startYear <= lastYear) {
      const qualificationYear = {
        id: startYear.toString(),
        label: startYear.toString(),
      };
      years.push(qualificationYear);
      startYear++;
    }
    return years;
  }

  const [qualificationYears,] = useState<AutocompleteItem[] | null>(
    getQualificationYears((new Date(personalDetails?.dateOfBirth!).getFullYear() + 16)));

  useEffect(() => {
    if (personalDetails !== undefined && personalDetails !== null
      && registrationDetails !== undefined && registrationDetails !== null) {
      reset({
        [QualificationCountry.name]: registrationDetails?.qualificationCountryId,
        [PrimaryMedicalDegreeCollege.name]: registrationDetails?.qualificationInstitutionId,
        [QualificationYear.name]: registrationDetails?.qualificationYear?.toString() ?? '',
        [DOB.name]: personalDetails.dateOfBirth,
        [OtherQualifications.name]: registrationDetails?.otherQualifications
      });
    }
  }, [personalDetails, registrationDetails, reset]);

  return (
    <>
      <Box sx={{ minWidth: "100%" }} component={Paper}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

          <Grid item xs={12}>
            <AppAutocomplete name={QualificationCountry.name}
              label={QualificationCountry.label}
              items={MapCountriesToAutocompleteItems(countries ?? [])}
              disabled={registrationDetails?.qualificationCountryId !== null &&
                registrationDetails?.qualificationCountryId !== ""} />
          </Grid>

          {getValues([OtherPrimaryMedicalDegreeCollege.name]).toString() === '' &&
            <Grid item xs={12}>
              <AppAutocomplete name={PrimaryMedicalDegreeCollege.name}
                label={PrimaryMedicalDegreeCollege.label}
                items={MapInstitutionsToAutocompleeItems(educationInstitutions ?? [],
                  getValues([QualificationCountry.name]).toString())}
                disabled={registrationDetails?.qualificationInstitutionId !== null &&
                  registrationDetails?.qualificationInstitutionId !== ""} />
            </Grid>
          }

          {getValues([PrimaryMedicalDegreeCollege.name]).toString() === '' &&
            <Grid item xs={12}>
              <AppTextareaInput name={OtherPrimaryMedicalDegreeCollege.name}
                label={OtherPrimaryMedicalDegreeCollege.label}
                disabled={registrationDetails?.qualificationInstitutionOther !== null &&
                  registrationDetails?.qualificationInstitutionOther !== ""} />
            </Grid>
          }

          <Grid item xs={12}>
            <AppAutocomplete control={control}
              name={QualificationYear.name}
              label={QualificationYear.label}
              items={qualificationYears ?? []}
              disabled={registrationDetails?.qualificationYear !== undefined &&
                registrationDetails?.qualificationYear !== null} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} multiline
              name={OtherQualifications.name}
              label={OtherQualifications.label} />
          </Grid>

          <Grid item xs={12}></Grid>

        </Grid>
      </Box>
    </>
  );
}
