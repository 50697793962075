import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { SummaryDto } from "../../app/models/summary/summaryDto";
import { useAppSelector } from "../../app/store/configureStore";
import { GetColorCode } from "../../app/utils/util";
import React from "react";
import { useGetCreditSummary } from "../../app/customHooks/useGetCreditSummary";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    gridItem: {
        textAlign: 'center',
        paddingTop: '16px'
    }
}));

export default function SummaryForm() {
    const { summaryData, getCreditSummaryStatus } = useAppSelector(state => state.pcs);
    const classes = useStyles();

    useGetCreditSummary()

    if (getCreditSummaryStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message='Loading summary...' />
    }

    return (
        <>
            <div className={classes.root}>
                <Box sx={{ flexGrow: 1 }} component={Paper}>
                    <Grid container component={Paper} sx={{
                        backgroundColor: '#616161',
                        color: "#fff",
                        padding: 2,
                        textAlign: 'left'
                    }}>
                        <Grid item xs={1} className={classes.alignCenter}>
                            PCS Year
                        </Grid>
                        <Grid item xs={2} className={classes.alignCenter}>
                            Engaged in  Parctice of Medicine
                        </Grid>
                        <Grid item xs={1.5} className={classes.alignCenter}>
                            External
                        </Grid>
                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Workbased Learning
                        </Grid>
                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Practice Review
                        </Grid>
                        <Grid item xs={1.5} className={classes.alignCenter}>
                            PDP
                        </Grid>
                        <Grid item xs={1.5} className={classes.alignCenter}>
                            On Target
                        </Grid>
                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Verification Status
                        </Grid>
                    </Grid>

                    {/* {summaryData?.map((item: SummaryDto[], index: number) => (
                        <React.Fragment key={index}>
                            <Grid container component={Paper}>

                                <Grid item xs={1}>
                                    <Grid className={classes.gridItem} sx={{
                                        color: 'primary.main'
                                    }}>
                                        {item[0].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={2}>
                                    <Grid className={classes.gridItem}>
                                        {item[1].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={1.5} sx={{ padding: 1 }}>
                                    <Grid sx={{
                                        backgroundColor: GetColorCode(item[2].status.toUpperCase()),
                                        textAlign: 'center',
                                        padding: 1
                                    }}>
                                        {item[2].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={1.5} sx={{ padding: 1 }}>
                                    <Grid sx={{
                                        backgroundColor: GetColorCode(item[3].status.toUpperCase()),
                                        textAlign: 'center',
                                        padding: 1
                                    }}>
                                        {item[3].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={1.5} sx={{ padding: 1 }}>
                                    <Grid sx={{
                                        backgroundColor: GetColorCode(item[4].status.toUpperCase()),
                                        textAlign: 'center',
                                        padding: 1
                                    }}>
                                        {item[4].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={1.5} sx={{ padding: 1 }}>
                                    <Grid sx={{
                                        backgroundColor: GetColorCode(item[5].status.toUpperCase()),
                                        textAlign: 'center',
                                        padding: 1
                                    }}>
                                        {item[5].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={1.5} sx={{ padding: 1 }}>
                                    <Grid sx={{
                                        backgroundColor: GetColorCode(item[6].status.toUpperCase()),
                                        textAlign: 'center',
                                        padding: 1
                                    }}>
                                        {item[6].text}
                                    </Grid>
                                </Grid>

                                <Grid item xs={1.5}>
                                    <Grid className={classes.gridItem}>
                                        {item[7].text}
                                    </Grid>
                                </Grid>

                            </Grid>
                        </React.Fragment>
                    ))} */}

                </Box>
            </div >
        </>
    )
}