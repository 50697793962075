import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { WorkPostDto } from "../models/workpost/workPostDto";

interface WorkpostState {
    workpostList: WorkPostDto[] | null;
    workpost: WorkPostDto | null;
    workpostStatus: ApiRequestStatus;
    workpostListStatus: ApiRequestStatus;
    saveWorkpostStatus: ApiRequestStatus;
    deleteWorkpostStatus: ApiRequestStatus;
}

const initialState: WorkpostState = {
    workpostList: null,
    workpost: null,
    workpostStatus: ApiRequestStatus.Idle,
    workpostListStatus: ApiRequestStatus.Idle,
    saveWorkpostStatus: ApiRequestStatus.Idle,
    deleteWorkpostStatus: ApiRequestStatus.Idle
}

export const getWorkpostListAsync = createAsyncThunk<WorkPostDto[]>(
    'workpost/getWorkpostListAsync',
    async (_, thunkAPI) => {
        try {
            return agent.WorkPost.getWorkpostListAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getWorkpostAsync = createAsyncThunk<WorkPostDto, string>(
    'workpost/getWorkpostState',
    async (workpostId, thunkAPI) => {
        try {
            return agent.WorkPost.getWorkpostByIdAsync(workpostId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveWorkpostAsync = createAsyncThunk<void, WorkPostDto>(
    'workpost/saveWorkpostAsync',
    async (workpostDto, thunkAPI) => {
        try {
            return agent.WorkPost.submitWorkpostAsync(workpostDto);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteWorkpostAsync = createAsyncThunk<void, string>(
    'workpost/deleteWorkpostAsync',
    async (workpostId, thunkAPI) => {
        try {
            return agent.WorkPost.deleteWorkpostAsync(workpostId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const workpostSlice = createSlice({
    name: 'workpost',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getWorkpostListAsync.pending, (state) => {
            state.workpostListStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getWorkpostListAsync.fulfilled, (state, action) => {
            state.workpostListStatus = ApiRequestStatus.Fulfilled;
            state.workpostList = action.payload;
        });
        builder.addCase(getWorkpostListAsync.rejected, (state) => {
            state.workpostListStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getWorkpostAsync.pending, (state) => {
            state.workpostStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getWorkpostAsync.fulfilled, (state, action) => {
            state.workpostStatus = ApiRequestStatus.Fulfilled;
            state.workpost = action.payload;
        });
        builder.addCase(getWorkpostAsync.rejected, (state) => {
            state.workpostStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveWorkpostAsync.pending, (state) => {
            state.saveWorkpostStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveWorkpostAsync.fulfilled, (state, action) => {
            state.workpost = action.meta.arg;
            state.saveWorkpostStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveWorkpostAsync.rejected, (state) => {
            state.saveWorkpostStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(deleteWorkpostAsync.pending, (state) => {
            state.deleteWorkpostStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteWorkpostAsync.fulfilled, (state, action) => {
            state.deleteWorkpostStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(deleteWorkpostAsync.rejected, (state) => {
            state.deleteWorkpostStatus = ApiRequestStatus.Rejected;
        });
    })
})
