import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { StaticDataResponseState } from "../models/staticData/staticDataResponseState";
import { ReferenceData } from "../models/staticData/referenceData";

const initialState: StaticDataResponseState = {
    titles: null,
    genders: null,
    countries: null,
    countrySubdivisions: null,
    hospitals: null,
    educationInstitutions: null,
    institutionAwards: null,
    registrationTypes: null,
    schemes: null,
    specialities: null,
    workPostLevels: null,
    activityTypes: null,
    activityTypeTargets: null,
    pcsEnrolmentTypes: null,
    verificationStatus: null,
    goodPracticeDomains: null,
    cpdCategories: null,
    cpdRecordStatuses: null,
    pcsPdpStatuses: null,
    disciplines: null,
    areasOfPractice: null,
    publicationPreferences: null,
    famMembershipTypes: null,
    famAffiliateOnboardingStatuses: null,
    famAffiliateCategories: null,
    famAffiliatePathways: null,
    royalColleges: null,
    famOnboardingStatuses: null,
    famEditableOnboardingStatusCodes: null,
    alumniVolunteeringInterestTypes: null,
    famVolunteeringInterestTypes: null,
    documentTypes: null,
    famTransferDocTypes: null,
    products: null,
    paymentMethods: null,
    paymentStatuses: null,
    funds: null,
    recurrencePeriods: null,
    currencies: null,
    subscriptionIntervalUnits: null,
    directDebitCreditors: null,

    staticDataStateStatus: ApiRequestStatus.Idle
}

export const getReferenceDataAsync = createAsyncThunk<ReferenceData>(
    'referenceData/getReferenceDataAsync',
    async (_, thunkAPI) => {
        try {
            return agent.ReferenceData.getReferenceData();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const referenceDataSlice = createSlice({
    name: 'referenceDataSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getReferenceDataAsync.pending, (state) => {
            state.staticDataStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getReferenceDataAsync.fulfilled, (state, action) => {
            state.titles = action.payload.titles;
            state.genders = action.payload.genders;
            state.countries = action.payload.countries;
            state.countrySubdivisions = action.payload.countrySubdivisions;
            state.hospitals = action.payload.hospitals;
            state.educationInstitutions = action.payload.educationInstitutions;
            state.institutionAwards = action.payload.institutionAwards;
            state.registrationTypes = action.payload.registrationTypes;
            state.schemes = action.payload.schemes;
            state.specialities = action.payload.specialities;
            state.workPostLevels = action.payload.workPostLevels;
            state.activityTypes = action.payload.activityTypes;
            state.activityTypeTargets = action.payload.activityTypeTargets;
            state.pcsEnrolmentTypes = action.payload.pcsEnrolmentTypes;
            state.verificationStatus = action.payload.verificationStatus;
            state.goodPracticeDomains = action.payload.goodPracticeDomains;
            state.cpdCategories = action.payload.cpdCategories;
            state.cpdRecordStatuses = action.payload.cpdRecordStatuses;
            state.pcsPdpStatuses = action.payload.pcsPdpStatuses;
            state.disciplines = action.payload.disciplines;
            state.areasOfPractice = action.payload.areasOfPractice;
            state.publicationPreferences = action.payload.publicationPreferences;
            state.famMembershipTypes = action.payload.famMembershipTypes;
            state.famAffiliateOnboardingStatuses = action.payload.famAffiliateOnboardingStatuses;
            state.famAffiliateCategories = action.payload.famAffiliateCategories;
            state.famAffiliatePathways = action.payload.famAffiliatePathways;
            state.royalColleges = action.payload.royalColleges;
            state.famOnboardingStatuses = action.payload.famOnboardingStatuses;
            state.famEditableOnboardingStatusCodes = action.payload.famEditableOnboardingStatusCodes;
            state.alumniVolunteeringInterestTypes = action.payload.alumniVolunteeringInterestTypes.filter(x => x.showInPortal === true);
            state.famVolunteeringInterestTypes = action.payload.famVolunteeringInterestTypes.filter(x => x.showInPortal === true);
            state.documentTypes = action.payload.documentTypes;
            state.famTransferDocTypes = action.payload.famTransferDocTypes;
            state.products = action.payload.products;
            state.paymentMethods = action.payload.paymentMethods;
            state.paymentStatuses = action.payload.paymentStatuses;
            state.funds = action.payload.funds;
            state.recurrencePeriods = action.payload.recurrencePeriods;
            state.currencies = action.payload.currencies;
            state.subscriptionIntervalUnits = action.payload.subscriptionIntervalUnits;
            state.directDebitCreditors = action.payload.directDebitCreditors;

            state.staticDataStateStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getReferenceDataAsync.rejected, (state) => {
            state.staticDataStateStatus = ApiRequestStatus.Rejected;
        });
    })
})
