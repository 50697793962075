import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";

interface paymentState {
    paymentId: string | null;
    paymentIdStatus: ApiRequestStatus;
}

const initialState: paymentState = {
    paymentId: null,
    paymentIdStatus: ApiRequestStatus.Idle
}

export const getPaymentIdFromBasketAsync = createAsyncThunk<string, string>(
    'shoppingBasket/getPaymentIdFromBasketAsync',
    async (shoppingBasketId, thunkAPI) => {
        try {
            return agent.ShoppingBasket.getPaymentIdFromBasketAsync(shoppingBasketId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const shoppingBasketSlice = createSlice({
    name: 'shoppingBasketSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPaymentIdFromBasketAsync.pending, (state) => {
            state.paymentIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPaymentIdFromBasketAsync.fulfilled, (state, action) => {
            state.paymentId = action.payload;
            state.paymentIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getPaymentIdFromBasketAsync.rejected, (state) => {
            state.paymentIdStatus = ApiRequestStatus.Rejected;
        });
    })
})
