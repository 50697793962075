import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getPcsEnrolmentYearsAsync } from '../slices/pcsEnrolmentSlice';

export const useGetPcsEnrolmentYears = () => {
    const dispatch = useAppDispatch();
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);

    useEffect(() => {
        if (!enrolmentYears && enrolmentYearsStatus === ApiRequestStatus.Idle) {
            dispatch(getPcsEnrolmentYearsAsync())
        }
    }, [enrolmentYears, enrolmentYearsStatus, dispatch]);

    return { enrolmentYearsStatus };
};
