import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateDirectDebitMandateCommand } from "../models/directDebit/createDirectDebitMandateCommand";
import { DirectDebitMandate } from "../models/directDebit/directDebitMandate";
import { CreatePaymentIntentFromBasketCommand } from "../models/directDebit/createPaymentIntentFromBasketCommand";

interface directDebitState {
    reference: string | null;
    directDebitMandate: DirectDebitMandate | null;
    directDebitMandates: DirectDebitMandate[] | null;
    directDebitPaymentIntentId: string | null;

    submitDirectDebitDetailsStatus: ApiRequestStatus;
    directDebitMandateByIdStatus: ApiRequestStatus;
    directDebitMandatesStatus: ApiRequestStatus;
    deleteDirectDebitMandatesStatus: ApiRequestStatus;
    directDebitPaymentIntentIdStatus: ApiRequestStatus;
}

const initialState: directDebitState = {
    reference: null,
    directDebitMandate: null,
    directDebitMandates: null,
    directDebitPaymentIntentId: null,

    submitDirectDebitDetailsStatus: ApiRequestStatus.Idle,
    directDebitMandateByIdStatus: ApiRequestStatus.Idle,
    directDebitMandatesStatus: ApiRequestStatus.Idle,
    deleteDirectDebitMandatesStatus: ApiRequestStatus.Idle,
    directDebitPaymentIntentIdStatus: ApiRequestStatus.Idle
}

export const submitDirectDebitDetailsAsync = createAsyncThunk<string, CreateDirectDebitMandateCommand>(
    'directDebitMandate/submitDirectDebitDetailsAsync',
    async (createDirectDebitMandateCommand, thunkAPI) => {
        try {
            return agent.DirectDebitMandate.submitDirectDebitDetailsAsync(createDirectDebitMandateCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDirectDebitMandateByIdAsync = createAsyncThunk<DirectDebitMandate, string>(
    'directDebitMandate/getDirectDebitMandateByIdAsync',
    async (id, thunkAPI) => {
        try {
            return agent.DirectDebitMandate.getDirectDebitMandateByIdAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDirectDebitMandatesAsync = createAsyncThunk<DirectDebitMandate[], void>(
    'directDebitMandate/getDirectDebitMandatesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.DirectDebitMandate.getDirectDebitMandatesAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteDirectDebitMandateByIdAsync = createAsyncThunk<DirectDebitMandate[], string>(
    'directDebitMandate/deleteDirectDebitMandateByIdAsync',
    async (id, thunkAPI) => {
        try {
            return agent.DirectDebitMandate.deleteDirectDebitMandateByIdAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const payByDDMandateFromBasketAsync = createAsyncThunk<string, CreatePaymentIntentFromBasketCommand>(
    'directDebitMandate/payByDDMandateFromBasketAsync',
    async (createPaymentIntentFromBasketCommand, thunkAPI) => {
        try {
            return agent.DirectDebitMandate.payByDDMandateFromBasketAsync(createPaymentIntentFromBasketCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const directDebitMandateSlice = createSlice({
    name: 'directDebitMandateSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(submitDirectDebitDetailsAsync.pending, (state) => {
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitDirectDebitDetailsAsync.fulfilled, (state, action) => {
            state.reference = action.payload;
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitDirectDebitDetailsAsync.rejected, (state) => {
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDirectDebitMandateByIdAsync.pending, (state) => {
            state.directDebitMandateByIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitMandateByIdAsync.fulfilled, (state, action) => {
            state.directDebitMandate = action.payload;
            state.directDebitMandateByIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitMandateByIdAsync.rejected, (state) => {
            state.directDebitMandateByIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDirectDebitMandatesAsync.pending, (state) => {
            state.directDebitMandatesStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDirectDebitMandatesAsync.fulfilled, (state, action) => {
            state.directDebitMandates = action.payload;
            state.directDebitMandatesStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDirectDebitMandatesAsync.rejected, (state) => {
            state.directDebitMandatesStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(deleteDirectDebitMandateByIdAsync.pending, (state) => {
            state.deleteDirectDebitMandatesStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteDirectDebitMandateByIdAsync.fulfilled, (state, action) => {
            state.deleteDirectDebitMandatesStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(deleteDirectDebitMandateByIdAsync.rejected, (state) => {
            state.deleteDirectDebitMandatesStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(payByDDMandateFromBasketAsync.pending, (state) => {
            state.directDebitPaymentIntentIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(payByDDMandateFromBasketAsync.fulfilled, (state, action) => {
            state.directDebitPaymentIntentId = action.payload;
            state.directDebitPaymentIntentIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(payByDDMandateFromBasketAsync.rejected, (state) => {
            state.directDebitPaymentIntentIdStatus = ApiRequestStatus.Rejected;
        });
    })
})
