import { Box, Grid, Typography } from "@mui/material";

export default function EventApprovalForm() {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>PCS event approval</Typography>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}