import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FAMDetails } from "../models/membership/famDetails";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { FamMembershipSubscription } from "../models/membership/famMembershipSubscription";
import { FAMOnboarding } from "../models/membership/famOnboarding";
import { FAMMembershipType } from "../models/membership/famMembershipType";

interface famState {
    famDetails: FAMDetails | null;
    famMembershipSubscriptions: FamMembershipSubscription[] | null;
    currentYear: number | null;
    famOnboardingDetails: FAMOnboarding[] | null;
    currentMembershipType: FAMMembershipType | null;
    canUseDirectDebitMandate: boolean | null;

    famDetailsStatus: ApiRequestStatus;
    famOnboardingDetailsStatus: ApiRequestStatus;
    updateFamDetailsStatus: ApiRequestStatus;
    addFamDetailsStatus: ApiRequestStatus;
    famSubscriptionsStatus: ApiRequestStatus;
    currentYearStatus: ApiRequestStatus;
    currentMembershipTypeStatus: ApiRequestStatus;
    canUseDirectDebitMandateStatus: ApiRequestStatus;
}

const initialState: famState = {
    famDetails: null,
    famMembershipSubscriptions: null,
    currentYear: null,
    famOnboardingDetails: null,
    currentMembershipType: null,
    canUseDirectDebitMandate: null,

    famDetailsStatus: ApiRequestStatus.Idle,
    updateFamDetailsStatus: ApiRequestStatus.Idle,
    addFamDetailsStatus: ApiRequestStatus.Idle,
    famSubscriptionsStatus: ApiRequestStatus.Idle,
    currentYearStatus: ApiRequestStatus.Idle,
    famOnboardingDetailsStatus: ApiRequestStatus.Idle,
    currentMembershipTypeStatus: ApiRequestStatus.Idle,
    canUseDirectDebitMandateStatus: ApiRequestStatus.Idle
}

export const getFAMDetails = createAsyncThunk<FAMDetails, string>(
    'famSlice/getFAMDetails',
    async (personId, thunkAPI) => {
        try {
            return agent.Fam.getFAMDetails(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFamSubscriptionsAsync = createAsyncThunk<FamMembershipSubscription[]>(
    'famSlice/getFamSubscriptionsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Fam.getFamSubscriptionsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateFAMDetails = createAsyncThunk<boolean, FAMDetails>(
    'famSlice/updateFAMDetails',
    async (famDetails, thunkAPI) => {
        try {
            return agent.Fam.updateFamDetails(famDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addFAMDetails = createAsyncThunk<boolean, FAMDetails>(
    'famSlice/addFAMDetails',
    async (famDetails, thunkAPI) => {
        try {
            return agent.Fam.addFamDetails(famDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCurrentYearAsync = createAsyncThunk<number>(
    'famSlice/getCurrentYearAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Fam.getCurrentYear();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFamExamsAsync = createAsyncThunk<FAMOnboarding[]>(
    'famSlice/getFamExamsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Fam.getFamExamDetailsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCurrentMembershipType = createAsyncThunk<FAMMembershipType>(
    'famSlice/getCurrentMembershipType',
    async (_, thunkAPI) => {
        try {
            return agent.Fam.getCurrentMembershipTypeAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCanUseDirectDebitMandateAsync = createAsyncThunk<boolean>(
    'famSlice/getCanUseDirectDebitMandateAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Fam.getCanUseDirectDebitMandateAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const famSlice = createSlice({
    name: 'famSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(updateFAMDetails.pending, (state) => {
            state.updateFamDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateFAMDetails.fulfilled, (state, action) => {
            state.updateFamDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateFAMDetails.rejected, (state) => {
            state.updateFamDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(addFAMDetails.pending, (state) => {
            state.addFamDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(addFAMDetails.fulfilled, (state) => {
            state.addFamDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(addFAMDetails.rejected, (state) => {
            state.addFamDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFAMDetails.pending, (state) => {
            state.famDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFAMDetails.fulfilled, (state, action) => {
            state.famDetails = action.payload;
            state.famDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getFAMDetails.rejected, (state) => {
            state.famDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFamSubscriptionsAsync.pending, (state) => {
            state.famSubscriptionsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamSubscriptionsAsync.fulfilled, (state, action) => {
            state.famMembershipSubscriptions = action.payload;
            state.famSubscriptionsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getFamSubscriptionsAsync.rejected, (state) => {
            state.famSubscriptionsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCurrentYearAsync.pending, (state) => {
            state.currentYearStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCurrentYearAsync.fulfilled, (state, action) => {
            state.currentYear = action.payload;
            state.currentYearStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getCurrentYearAsync.rejected, (state) => {
            state.currentYearStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFamExamsAsync.pending, (state) => {
            state.famOnboardingDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamExamsAsync.fulfilled, (state, action) => {
            state.famOnboardingDetailsStatus = ApiRequestStatus.Fulfilled;
            state.famOnboardingDetails = action.payload;
        });
        builder.addCase(getFamExamsAsync.rejected, (state) => {
            state.famOnboardingDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCurrentMembershipType.pending, (state) => {
            state.currentMembershipTypeStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCurrentMembershipType.fulfilled, (state, action) => {
            state.currentMembershipType = action.payload;
            state.currentMembershipTypeStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getCurrentMembershipType.rejected, (state) => {
            state.currentMembershipTypeStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCanUseDirectDebitMandateAsync.pending, (state) => {
            state.canUseDirectDebitMandateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCanUseDirectDebitMandateAsync.fulfilled, (state, action) => {
            state.canUseDirectDebitMandate = action.payload;
            state.canUseDirectDebitMandateStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getCanUseDirectDebitMandateAsync.rejected, (state) => {
            state.canUseDirectDebitMandateStatus = ApiRequestStatus.Rejected;
        });
    })
})
