import { createTheme } from "@mui/material";

const paletteType = 'light';

export const theme = createTheme({
    palette: {
        mode: paletteType,
        primary: {
            light: "#F08080",
            main: "#DE1834",
            dark: "#DC143C",
            contrastText: "#fff"
        },
        background: {
            default: '#ffffff'
        }
    },
    components: {
        // GLOBAL CHANGES TO DEFAULT/BASE THEME
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application!
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    boxShadow: "none"
                    , '&:hover': {
                        boxShadow: "none"
                    },
                    fontSize: '1rem',
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    marginTop: '0px !important',
                    marginBottom: '0px !important',
                },
                outlined: {
                    border: '0',
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    boxShadow: "none"
                    , '&:hover': {
                        boxShadow: "none"
                    },
                    fontSize: '1rem',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {

                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#222',
                }
            },
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    paddingLeft: '4px',
                }
            },
        }
    },
});