import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFAMDetails } from '../slices/famSlice';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../enums/userClaims';

export const useGetFamDetails = () => {
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    const dispatch = useAppDispatch();
    const { famDetails, famDetailsStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (personId !== "" && famDetails === null && famDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getFAMDetails(personId));
        }
    }, [personId, famDetails, famDetailsStatus, dispatch]);

    return { famDetailsStatus };
};
