import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFamExamsAsync } from '../slices/famSlice';

export const useGetFamExams = () => {
    const dispatch = useAppDispatch();
    const { famOnboardingDetails, famOnboardingDetailsStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (famOnboardingDetails === null && famOnboardingDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getFamExamsAsync());
        }
    }, [famOnboardingDetails, famOnboardingDetailsStatus, dispatch]);

    return { famOnboardingDetailsStatus };
};
