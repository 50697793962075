import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PlannedActivity } from "../../features/pcsPlan/models/plannedActivity";
import { ReviewerDetails } from "../../features/pcsPlan/models/reviewerDetails";
import { CancelPlanParameters } from "../../features/pcsPlan/models/cancelPlanParameters";
import { SummaryDto } from "../models/summary/summaryDto";
import { ReviewerDetailsDto } from "../../features/pcsPlan/models/reviewerDetailsDto";
import { DomainSummaryDto } from "../../features/pcsPlan/models/domainSummaryDto";
import { ConfirmReviewerDetailsParameters } from "../../features/pcsPlan/models/confirmReviewerDetailsParameters";
import { RevieweeCommentDto } from "../../features/pcsPlan/models/revieweeCommentDto";

interface PlanState {
    isPlannedActivityUpdated: boolean | null;
    reviewerDetails: ReviewerDetailsDto | null;
    activitiesAcrossDomains: DomainSummaryDto | null;
    planStateStatus: ApiRequestStatus;
}

const initialState: PlanState = {
    isPlannedActivityUpdated: null,
    reviewerDetails: null,
    activitiesAcrossDomains: null,
    planStateStatus: ApiRequestStatus.Idle
}

export interface PCSSummaryDto {
    summaryData: SummaryDto[][] | null;
}

export const getActivitiesAcrossDomainsAsync = createAsyncThunk<DomainSummaryDto>(
    'pcsPdp/getActivitiesAcrossDomainsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PcsPdp.getActivitiesAcrossDomains();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchReviewerByImcNumberAsync = createAsyncThunk<ReviewerDetailsDto, string>(
    'pcsPdp/searchReviewerByImcNumberAsync',
    async (imcNumber, thunkAPI) => {
        try {
            return agent.PcsPdp.searchReviewerByImcNumber(imcNumber);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchReviewerByPersonIdAsync = createAsyncThunk<ReviewerDetailsDto, string>(
    'pcsPdp/searchReviewerByPersonIdAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsPdp.searchReviewerByPersonId(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updatePlannedActivityAsync = createAsyncThunk<boolean, PlannedActivity>(
    'pcsPdp/updatePlannedActivityAsync',
    async (plannedActivity, thunkAPI) => {
        try {
            return agent.PcsPdp.updatePlannedActivity(plannedActivity);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const confirmReviewerDetailsAsync = createAsyncThunk<boolean, ConfirmReviewerDetailsParameters>(
    'pcsPdp/confirmReviewerDetailsAsync',
    async (confirmReviewerDetailsParameters, thunkAPI) => {
        try {
            return agent.PcsPdp.confirmReviewerDetails(confirmReviewerDetailsParameters);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitReviewerDetailsAsync = createAsyncThunk<boolean, ReviewerDetails>(
    'pcsPdp/submitReviewerDetailsAsync',
    async (reviewerDetails, thunkAPI) => {
        try {
            return agent.PcsPdp.submitReviewerDetails(reviewerDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveRevieweeCommentsAsync = createAsyncThunk<boolean, RevieweeCommentDto>(
    'pcsPdp/saveRevieweeCommentsAsync',
    async (revieweeComment, thunkAPI) => {
        try {
            return agent.PcsPdp.saveRevieweeComment(revieweeComment);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cancelPlanAsync = createAsyncThunk<boolean, CancelPlanParameters>(
    'pcsPdp/cancelPlanAsync',
    async (cancelPlanParameters, thunkAPI) => {
        try {
            return agent.PcsPdp.cancelPlan(cancelPlanParameters);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsPdpSlice = createSlice({
    name: 'pcsPdpSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        
        builder.addCase(updatePlannedActivityAsync.fulfilled, (state, action) => {
            state.isPlannedActivityUpdated = action.payload;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getActivitiesAcrossDomainsAsync.fulfilled, (state, action) => {
            state.activitiesAcrossDomains = action.payload;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(submitReviewerDetailsAsync.fulfilled, (state, action) => {
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(cancelPlanAsync.fulfilled, (state, action) => {
            state.reviewerDetails = null;
            state.planStateStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addMatcher(isAnyOf(submitReviewerDetailsAsync.fulfilled,
            saveRevieweeCommentsAsync.fulfilled), (state) => {
                state.planStateStatus = ApiRequestStatus.Fulfilled;
            });

        builder.addMatcher(isAnyOf(searchReviewerByImcNumberAsync.fulfilled,
            searchReviewerByPersonIdAsync.fulfilled), (state, action) => {
                state.reviewerDetails = action.payload;
                state.planStateStatus = ApiRequestStatus.Fulfilled;
            });

        builder.addMatcher(isAnyOf(updatePlannedActivityAsync.pending,
            cancelPlanAsync.pending,
            searchReviewerByImcNumberAsync.pending,
            searchReviewerByPersonIdAsync.pending,
            getActivitiesAcrossDomainsAsync.pending,
            confirmReviewerDetailsAsync.pending,
            submitReviewerDetailsAsync.pending,
            saveRevieweeCommentsAsync.pending), (state) => {
                state.planStateStatus = ApiRequestStatus.Pending;
            });

        builder.addMatcher(isAnyOf(updatePlannedActivityAsync.rejected,
            cancelPlanAsync.rejected,
            searchReviewerByImcNumberAsync.rejected,
            searchReviewerByPersonIdAsync.rejected,
            getActivitiesAcrossDomainsAsync.rejected,
            confirmReviewerDetailsAsync.rejected,
            submitReviewerDetailsAsync.rejected,
            saveRevieweeCommentsAsync.rejected), (state) => {
                state.planStateStatus = ApiRequestStatus.Rejected;
            });
    })
})
