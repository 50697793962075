import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCanUseDirectDebitMandateAsync } from '../slices/famSlice';

export const useGetCanUseDirectDebitMandate = () => {
    const dispatch = useAppDispatch();
    const { canUseDirectDebitMandate, canUseDirectDebitMandateStatus } = useAppSelector(state => state.famDetails);

    useEffect(() => {
        if (canUseDirectDebitMandate === null &&
            canUseDirectDebitMandateStatus === ApiRequestStatus.Idle) {
            dispatch(getCanUseDirectDebitMandateAsync());
        }
    }, [canUseDirectDebitMandate, canUseDirectDebitMandateStatus, dispatch]);

    return { canUseDirectDebitMandateStatus };
};
