import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../enums/userClaims';
import { getAlumniDetails } from '../slices/alumniSlice';

export const useGetAlumniDetails = () => {
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    const dispatch = useAppDispatch();
    const { alumnusDetails, alumnusDetailsStatus } = useAppSelector(state => state.alumniDetails);

    useEffect(() => {
        if (personId !== "" && alumnusDetails === null &&
            alumnusDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getAlumniDetails(personId));
        }
    }, [alumnusDetails, alumnusDetailsStatus, dispatch]);

    return { alumnusDetailsStatus };
};
