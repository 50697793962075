import { DirectDebitTypeEnum } from "../enums/directDebitTypeEnum";
import { PaymentFrequencyEnum } from "../enums/paymentFrequencyEnum";
import { AutocompleteItem } from "../models/common/autocompleteItem";
import { DropdownItem } from "../models/common/dropdownItem";
import { ActivityType } from "../models/staticData/activityType";
import { AreaOfPractice } from "../models/staticData/areaOfPractice";
import { Country } from "../models/staticData/country";
import { CountrySubdivision } from "../models/staticData/countrySubdivision";
import { CpdCategory } from "../models/staticData/cpdCategory";
import { CpdGoodPracticeDomain } from "../models/staticData/cpdGoodPracticeDomain";
import { CpdStatus } from "../models/staticData/cpdStatus";
import { Discipline } from "../models/staticData/discipline";
import { FamAffiliateCategory } from "../models/staticData/famAffiliateCategory";
import { FamAffiliatePathway } from "../models/staticData/famAffiliatePathway";
import { Fund } from "../models/staticData/fund";
import { Gender } from "../models/staticData/gender";
import { PublicationPreference } from "../models/staticData/publicationPreference";
import { RegistrationType } from "../models/staticData/registrationType";
import { RoyalCollege } from "../models/staticData/royalCollege";
import { Scheme } from "../models/staticData/scheme";
import { Speciality } from "../models/staticData/speciality";
import { Title } from "../models/staticData/title";
import { VolunteeringInterestType } from "../models/staticData/volunteeringInterestType";

export function MapDomainsToCheckboxItems(goodPracticeDomains: CpdGoodPracticeDomain[]) {
    let domainList: DropdownItem[] = [];

    goodPracticeDomains?.map((item: CpdGoodPracticeDomain) => {
        domainList.push({
            id: item.id,
            value: item.value
        });
        return true;
    });

    return domainList;
}

export function MapCategoriesToAutocompleteItems(categories: CpdCategory[]) {
    let categoryList: AutocompleteItem[] = [];

    categories?.map((item: CpdCategory) => {
        categoryList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return categoryList;
}

export function MapActivityTypesToAutocompleteItems(activityTypes: ActivityType[]) {
    let activityTypeList: AutocompleteItem[] = [];

    activityTypes?.map((item: ActivityType) => {
        activityTypeList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return activityTypeList;
}

export function MapStatusesToAutocompleteItems(statuses: CpdStatus[]) {
    let statusList: AutocompleteItem[] = [];

    statuses?.map((item: CpdStatus) => {
        statusList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return statusList;
}

export function MapRegistrationTypesToAutocompleteItems(registrationTypes: RegistrationType[]) {
    let registrationTypeList: AutocompleteItem[] = [];

    registrationTypes?.map((item: RegistrationType) => {
        registrationTypeList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return registrationTypeList;
}

export function MapSchemesToAutocompleteItems(schemes: Scheme[]) {
    let schemeList: AutocompleteItem[] = [];

    schemes?.map((item: Scheme) => {
        schemeList.push({
            id: item.id,
            label: item.value,
            code: item.code
        });
        return true;
    });

    return schemeList;
}

export function MapSpecialitiesToAutocompleteItems(specialities: Speciality[]) {
    let specialityList: AutocompleteItem[] = [];

    specialities?.map((item: Speciality) => {
        specialityList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return specialityList;
}

export function MapTitlesToAutocompleteItems(titles: Title[]) {
    let titleList: AutocompleteItem[] = [];

    titles?.map((item: Title) => {
        titleList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return titleList;
}

export function MapGendersToAutocompleteItems(genders: Gender[]) {
    let genderList: AutocompleteItem[] = [];

    genders?.map((item: Gender) => {
        genderList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return genderList;
}

export function MapFundsToAutocompleteItems(funds: Fund[]) {
    let fundList: AutocompleteItem[] = [];

    funds?.map((item: Fund) => {
        fundList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return fundList;
}

export function MapCountriesToAutocompleteItems(countries: Country[]) {
    let countryList: AutocompleteItem[] = [];

    countries?.map((item: Country) => {
        countryList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return countryList;
}

export function MapMagazinePreferenceToAutocompleteItems(publicationPreferences: PublicationPreference[]) {
    let publicationPreferenceList: AutocompleteItem[] = [];

    publicationPreferences?.map((item: PublicationPreference) => {
        publicationPreferenceList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return publicationPreferenceList;
}

export function MapCountrySubdivisionToAutocompleteItems(countrySubdivisions: CountrySubdivision[]) {
    let countrySubdivisionList: AutocompleteItem[] = [];

    countrySubdivisions?.map((item: Country) => {
        countrySubdivisionList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return countrySubdivisionList;
}

export function MapDisciplinesToAutocompleteItems(disciplines: Discipline[]) {
    let disciplineList: AutocompleteItem[] = [];

    disciplines?.map((item: Discipline) => {
        disciplineList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return disciplineList;
}

export function MapVolunteeringInterestTypesToAutocompleteItems(volunteeringInterestTypes: VolunteeringInterestType[]) {
    let volunteeringInterestTypeList: AutocompleteItem[] = [];

    volunteeringInterestTypes?.map((item: VolunteeringInterestType) => {
        volunteeringInterestTypeList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return volunteeringInterestTypeList;
}

export function MapAreaOfPracticesToAutocompleteItems(areaOfPractices: AreaOfPractice[]) {
    let areaOfPracticeList: AutocompleteItem[] = [];

    areaOfPractices?.map((item: AreaOfPractice) => {
        areaOfPracticeList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return areaOfPracticeList;
}

export function MapFamAffiliateCategoriesToAutocompleteItems(famAffiliateCategories: FamAffiliateCategory[]) {
    let famAffiliateCategoryList: AutocompleteItem[] = [];

    famAffiliateCategories?.map((item: FamAffiliateCategory) => {
        famAffiliateCategoryList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return famAffiliateCategoryList;
}

export function MapFamAffiliatePathwaysToAutocompleteItems(famAffiliatePathways: FamAffiliatePathway[]) {
    let famAffiliatePathwayList: AutocompleteItem[] = [];

    famAffiliatePathways?.map((item: FamAffiliatePathway) => {
        famAffiliatePathwayList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return famAffiliatePathwayList;
}

export function MapRoyalCollegesToAutocompleteItems(royalColleges: RoyalCollege[]) {
    let royalCollegeList: AutocompleteItem[] = [];

    royalColleges?.map((item: RoyalCollege) => {
        royalCollegeList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return royalCollegeList;
}

export function MapTypeOfDirectDebitsToAutocompleteItems() {
    const typeOfDirectDebits: AutocompleteItem[] = [
        {
            id: DirectDebitTypeEnum.RECURRENT,
            label: DirectDebitTypeEnum.RECURRENT
        },
        {
            id: DirectDebitTypeEnum.ONCE_OFF,
            label: DirectDebitTypeEnum.ONCE_OFF
        }
    ];

    return typeOfDirectDebits;
}

export function MapPaymentFrequencyToAutocompleteItem() {
    const paymentFrequencies: AutocompleteItem[] = [
        {
            id: PaymentFrequencyEnum.Monthly,
            label: PaymentFrequencyEnum.Monthly
        },
        {
            id: PaymentFrequencyEnum.Every3Months,
            label: PaymentFrequencyEnum.Every3Months
        },
        {
            id: PaymentFrequencyEnum.Yearly,
            label: PaymentFrequencyEnum.Yearly
        }
    ];

    return paymentFrequencies;
}
