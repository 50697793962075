import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getSelfServiceAsync } from '../slices/paymentSlice';

export const useGetSelfService = () => {
    const dispatch = useAppDispatch();
    const { heyCentricUrl, selfServiceStatus } = useAppSelector(state => state.payment);

    useEffect(() => {
        if (heyCentricUrl === null && selfServiceStatus === ApiRequestStatus.Idle) {
            dispatch(getSelfServiceAsync());
        }
    }, [heyCentricUrl, selfServiceStatus, dispatch]);

    return { selfServiceStatus };
};
