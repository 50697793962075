import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getPaymentsAsync } from '../slices/paymentSlice';

export const useGetPayments = () => {
    const dispatch = useAppDispatch();
    const { paymentsByPerson, paymentsByPersonStatus } = useAppSelector(state => state.payment);

    useEffect(() => {
        if (paymentsByPerson === null && paymentsByPersonStatus === ApiRequestStatus.Idle) {
            dispatch(getPaymentsAsync());
        }
    }, [paymentsByPerson, paymentsByPersonStatus, dispatch]);

    return { paymentsByPersonStatus };
};
